import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { permissions } from 'src/app/shared/constants/permission.constants';
import { PermissionsService } from 'src/app/shared/services/permissions/permissions.service';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.scss'],
})
export class RedirectorComponent implements OnInit {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService
  ) {}

  public ngOnInit(): void {
    const shouldRemoveRedirectAfter = window.location.href.endsWith('/redirector');
    this.navigateUserToDestination(shouldRemoveRedirectAfter);
  }

  private navigateUserToDestination(shouldRemoveRedirectAfter: boolean) {
    const url = sessionStorage.getItem('redirect');
    if (url && url !== '/logged-out') {
      // navigate the user to the destination url
      this.router.navigateByUrl(url);

      if (shouldRemoveRedirectAfter) {
        sessionStorage.removeItem('redirect');
      }
    } else {
      // no destination url available, navigate all users with MasterData.Read-permission to articles page, else to print page
      if (this.permissionsService.hasPermissions([permissions.MasterData.Read])) {
        this.router.navigateByUrl('/articles');
      } else {
        this.router.navigateByUrl('/printing');
      }
    }
  }
}
