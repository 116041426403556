import { UpdatePrinterPartiallyRequest } from '@api/model/models';
import { DefaultPaginationSize } from 'src/app/shared/paginationConstants';

export class GetPrinters {
  public static readonly type = '[Printer Management] Get Printers';

  constructor(
    public page: number = 1,
    public size: number = DefaultPaginationSize,
    public orderBy: string = null,
    public orderDirection: string = null,
    public searchText: string = null
  ) {}
}

export class UpdatePrinter {
  public static readonly type = '[Printer Management] Update Printer';

  constructor(public command: UpdatePrinterPartiallyRequest) {}
}
