import { Component, Input, OnInit, Optional, Self, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { getRandomId } from 'src/app/shared/extensions/string.extensions';
import { StandardComponentBase } from '../standard-component.base';
import { isRequired } from '../standard-control-value-accessor.extensions';

@Component({
  selector: 'app-standard-textbox',
  templateUrl: './standard-textbox.component.html',
  styleUrls: ['./standard-textbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StandardTextboxComponent extends StandardComponentBase implements ControlValueAccessor, OnInit {
  @Input() public shouldBeNumber = false;
  @Input() public addOnText?: string;
  @Input() public label: string;
  @Input() public hint: string;
  @Input() public set disabled(disableValue: boolean) {
    disableValue ? this.valueControl.disable() : this.valueControl.enable();
  }
  @Input() public validationError: string;
  @Input() public replaceTextbox: boolean;
  @Input() public set data(data: string | number | boolean) {
    this.valueControl.setValue(data);
  }
  @Input() public set focus(focusValue: boolean) {
    this.focusValue = focusValue;
    this.handleFocus();
  }
  @Input() public placeholder?: string;

  public id: string;
  public get isRequired() {
    const required = isRequired(this.control);
    return required;
  }

  public valueControl = new UntypedFormControl();

  private focusValue: boolean;

  constructor(@Self() @Optional() public control: NgControl) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
    this.id = getRandomId();
  }

  public ngOnInit(): void {
    this.handleFocus();
  }

  private handleFocus() {
    if (this.focusValue) {
      this.targetInputElement.nativeElement.focus();
    }
  }

  public onKeyDown(event: KeyboardEvent) {
    if (this.shouldBeNumber && event.key === 'e') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public onPaste(event: ClipboardEvent) {
    if (this.shouldBeNumber) {
      event.preventDefault();
      event.stopPropagation();
      const pastedData = ((event.clipboardData.getData('Text') as string) ?? '').replace(/\D/g, '');
      this.data += pastedData;
    }
  }

  public onChangeFn = (_: string | number | boolean) => {
    return;
  };

  public onTouchedFn = () => {
    return;
  };

  public writeValue(value: string | number | boolean): void {
    if (value != null && !Number.isNaN(value)) {
      this.data = value;
    } else {
      this.data = null;
    }
  }

  public registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChange() {
    this.onChangeFn(this.data);
  }

  public get data() {
    return this.valueControl.value;
  }

  public get disabled() {
    return this.valueControl.disabled;
  }
}
