import { Injectable } from '@angular/core';
import { SupportIssueService } from '@api/api/supportIssue.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CreateSupportIssue, GetSupportIssues, UpdateSupportIssue } from './support-issues.actions';
import { ISupportIssuesStateModel, supportIssuesStateModelDefault } from './support-issues.state.model';

@State<ISupportIssuesStateModel>(supportIssuesStateModelDefault)
@Injectable()
export class SupportIssuesState {
  @Selector([SupportIssuesState])
  public static getSupportIssues(state: ISupportIssuesStateModel) {
    return state.supportIssues;
  }

  constructor(private supportIssueService: SupportIssueService) {}

  @Action(CreateSupportIssue)
  public createSupportIssue(_: StateContext<ISupportIssuesStateModel>, action: CreateSupportIssue) {
    return this.supportIssueService.createSupportIssue(action.createSupportIssueRequest);
  }

  @Action(GetSupportIssues)
  public getSupportIssues({ patchState }: StateContext<ISupportIssuesStateModel>, action: GetSupportIssues) {
    return this.supportIssueService.getAllSupportIssues(action.page, action.size, action.orderBy, action.orderDirection, action.searchText).pipe(
      tap((supportIssues) => {
        patchState({
          supportIssues,
        });
      })
    );
  }

  @Action(UpdateSupportIssue)
  public updateSupportIssue(_: StateContext<ISupportIssuesStateModel>, action: UpdateSupportIssue) {
    return this.supportIssueService.updateSupportIssue(action.updateSupportIssueRequest.id, action.updateSupportIssueRequest);
  }
}
