import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  // Login URL
  loginUrl: `${environment.identityAuthority}/Account/Login`,
  logoutUrl: window.location.origin + '/logged-out',
  // Url of the Identity Provider
  issuer: `${environment.identityAuthority}`,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/redirector',
  silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
  // logoutUrl: window.location.origin + '/logout',
  postLogoutRedirectUri: window.location.origin + '/logged-out',
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'LabelManager.Angular',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'LabelManager.Core openid email profile',
};
