import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ICanDeactivate } from './can-deactivate.interface';

@Injectable()
export class DeactivationGuard implements CanDeactivate<ICanDeactivate> {
  public canDeactivate(component: ICanDeactivate) {
    try {
      return component.canDeactivate ? component.canDeactivate() : true;
    } catch {
      // if canDeactivate crashes at least allow navigation.
      return true;
    }
  }
}
