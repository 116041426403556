import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType, TableColumn } from '@swimlane/ngx-datatable';
import { map, Observable } from 'rxjs';
import { DefaultPaginationSize } from 'src/app/shared/paginationConstants';
import { TranslationService } from 'src/app/transloco-root/services/translation.service';

@Component({
  selector: 'app-standard-listview',
  templateUrl: './standard-listview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardListviewComponent {
  @ViewChild('dataTable') public dataTable: DatatableComponent;
  @ViewChild('expandableColumnTemplate') public expandableColumnTemplate: TemplateRef<string>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Input() public sourceObservable: Observable<any>;
  @Input() public expandableRowContent: TemplateRef<string>;
  @Input() public selectionType: SelectionType;
  @Input() public columns: Array<TableColumn>;
  @Input() public rows: [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public sorts: any;

  @Input() public externalPaging = true;
  @Input() public externalSorting = true;
  @Input() public rowHeight: number | 'auto' = 34;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() public rowSelected = new EventEmitter<any>();
  @Output() public pageChanged = new EventEmitter<{ page: number; pageSize: number }>();
  @Output() public sortChanged = new EventEmitter<{ orderBy: string; orderDirection: string }>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @Output() public rowExpandStateChanged = new EventEmitter<{ row: any; isExpanded: boolean }>();

  public ColumnMode = ColumnMode;
  public DefaultPaginationSize = DefaultPaginationSize;
  public currentPage: number;
  public internalPageNumber = 0;

  public messages$ = this.translationService.afterLanguageChanged$.pipe(
    map(() => {
      return {
        emptyMessage: this.translationService.translate('noDataMessages.standaloneView'),
        totalMessage: this.translationService.translate('defaults.totalItems'),
        selectedMessage: '',
      };
    })
  );

  constructor(public translationService: TranslationService) {}

  public changePage(event: { offset: number; pageSize: number }) {
    this.internalPageNumber = event.offset;
    this.currentPage = this.internalPageNumber + 1;

    this.pageChanged.emit({ page: this.currentPage, pageSize: event.pageSize });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public selectRow(selected: any) {
    this.rowSelected.emit(selected);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public onSort(event: any) {
    this.sortChanged.emit({ orderBy: event.column.prop, orderDirection: event.newValue });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public toggleExpandRow(row: any, isExpanded: boolean) {
    this.dataTable.rowDetail.toggleExpandRow(row);

    this.rowExpandStateChanged.emit({ row, isExpanded });
  }
}
