import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as masterDictionary from 'src/assets/i18n/de.json';
import { TranslationService } from '../services/translation.service';
import { NestedPropertyOf } from '../types/nested-key-of.type';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  public transform(dictionaryKey: NestedPropertyOf<typeof masterDictionary>, params?: { [key: string]: string }): Observable<string> {
    return this.translationService.afterLanguageChanged$.pipe(
      map(() => {
        const translation = this.translationService.translate(dictionaryKey, params);
        return translation;
      })
    );
  }
}
