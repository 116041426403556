import { Injectable } from '@angular/core';
import { TenantService } from '@api';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from './shared/services/authorization/authorization.service';
import { InitOAuth } from './shared/state/application.actions';

export const startupServiceFactory = (startupService: StartupService) => {
  return async () => await startupService.initializeAsync();
};

@Injectable()
export class StartupService {
  constructor(
    private tenantService: TenantService,
    private store: Store,
    private authorizationService: AuthorizationService,
    private spinnerService: NgxSpinnerService
  ) {}

  public async initializeAsync(): Promise<void> {
    const url = window.location.href.replace(window.location.origin, '');

    // save the users destination url to session storage
    // ignore redirect urls
    if (!url.includes('id_token') && !url.includes('redirector') && url?.length > 1) {
      sessionStorage.setItem('redirect', url);
    }

    this.spinnerService.show();

    try {
      await this.authorizationService.login();

      const tenantUserResult = await lastValueFrom(this.tenantService.getTenantUser('response').pipe(catchError(() => of(null))));

      await lastValueFrom(this.store.dispatch(new InitOAuth(tenantUserResult?.body)));
    } catch (error) {
      console.warn(error);
    } finally {
      this.spinnerService.hide();
    }
  }
}
