import { Injectable } from '@angular/core';
import { PrinterService } from '@api/api/printer.service';
import { PrinterDto } from '@api/model/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { GetPrinters, UpdatePrinter } from './printer-management.actions';
import { IPrinterManagementStateModel, printerManagementStateModelDefault } from './printer-management.state.model';

@State<IPrinterManagementStateModel>(printerManagementStateModelDefault)
@Injectable()
export class PrinterManagementState {
  @Selector([PrinterManagementState])
  public static getPrinters(state: IPrinterManagementStateModel) {
    return state.printers;
  }

  constructor(private printerService: PrinterService) {}

  @Action(GetPrinters)
  public getPrinters({ patchState }: StateContext<IPrinterManagementStateModel>, action: GetPrinters) {
    return this.printerService.getAllPrinters(action.page, action.size, action.orderBy, action.orderDirection, action.searchText).pipe(
      tap((printers) => {
        patchState({
          printers,
        });
      })
    );
  }

  @Action(UpdatePrinter)
  public updatePrinter({ getState, setState }: StateContext<IPrinterManagementStateModel>, action: UpdatePrinter) {
    return this.printerService.updatePrinterPartially(action.command.printerId, action.command).pipe(
      tap((printer) => {
        const state = getState();

        setState(
          patch({
            printers: patch({
              items: updateItem<PrinterDto>((p) => p.id === printer.id, printer),
              pages: { ...state.printers.pages },
            }),
          })
        );
      })
    );
  }
}
