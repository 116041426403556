import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@api/api.module';
import { Configuration } from '@api/configuration';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartupService, startupServiceFactory } from './app.startup';
import { AuthGuardService } from './auth/auth-guard.service';
import { PortalModule } from './portal/portal.module';
import { HttpErrorInterceptor } from './shared/services/http-error-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ApplicationState } from './shared/state/application.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.basePath,
        })
    ),
    // ! NGXS must be initialised for root before other feature state modules.
    NgxsModule.forRoot([ApplicationState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: [ApplicationState],
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    BrowserModule,
    SharedModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PortalModule,
    NgbModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    AuthGuardService,
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
