import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApplicationState } from '../../state/application.state';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private store: Store) {}

  public hasPermissions(requiredPermissions: string[]) {
    const sanitizedRequiredPermissions = requiredPermissions.map((permission) => permission.toString().toLowerCase());
    const userPermissions = this.store.selectSnapshot(ApplicationState.permissions).map((permission) => permission.toLowerCase());

    return sanitizedRequiredPermissions.every((permission) => userPermissions.includes(permission));
  }
}
