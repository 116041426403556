import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { LabelPreviewState } from './state/label-preview.state';

@NgModule({
  declarations: [],
  imports: [SharedModule, NgxsModule.forFeature([LabelPreviewState])],
})
export class LabelPreviewModule {}
