import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ComponentBaseDirective } from 'src/app/shared/component.base';
import { ComponentBaseService } from 'src/app/shared/component.base.service';
import { permissions } from 'src/app/shared/constants/permission.constants';

@Component({
  selector: 'app-notfound',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent extends ComponentBaseDirective implements OnInit {
  public errorType?: HttpStatusCode;
  public Forbidden = HttpStatusCode.Forbidden;
  public permissions = permissions;

  constructor(
    private activatedRoute: ActivatedRoute,
    componentBaseService: ComponentBaseService
  ) {
    super(componentBaseService);
  }

  public ngOnInit(): void {
    this.subscribe(this.activatedRoute.params.pipe(take(1)), (params) => {
      this.errorType = params['id'] ?? HttpStatusCode.NotFound;
    });

    this.spinnerService.hide(this.mainLoaderId);
  }
}
