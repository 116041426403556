import { Injectable, OnDestroy } from '@angular/core';
import { JobStatus, JobType, LabelTemplateService } from '@api';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { compose, iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import moment from 'moment';
import { Subscription, map } from 'rxjs';
import { BlobToImageConverterService } from 'src/app/shared/helpers/blob-to-image-converter.service';
import { GetPrinterSuggestions, SetPrintTimeSchemaParameters } from '../../printing/state/print.actions';
import { PrintState } from '../../printing/state/print.state';
import { TemplatePrintTimeParameters } from '../interfaces/template-print-time-parameters.interface';
import { LabelTemplateConfiguration } from '../models/printConfigurationItem';
import { LabelPreviewJobSchedulerService } from '../services/label-preview-job-scheduler.service';
import { CreateRawTemplatePreview, LoadTemplatePreviews, ResetPreviews, UpdateLabelPreview, UpdatePrintConfiguration, UpdatePrintTimeParameter, UpdateProductionDate } from './label-preview.actions';
import { ILabelPreviewStateModel, labelPreviewStateModelDefaults } from './label-preview.state.model';

@State<ILabelPreviewStateModel>(labelPreviewStateModelDefaults)
@Injectable()
export class LabelPreviewState implements OnDestroy {
  private previewEventSubscription: Subscription;

  @Selector([LabelPreviewState])
  public static getLabelTemplateConfigurations(state: ILabelPreviewStateModel) {
    return state.labelTemplateConfiguration;
  }

  @Selector([LabelPreviewState])
  public static getPrintConfiguration(state: ILabelPreviewStateModel) {
    return state.labelTemplateConfiguration.map((templateConfig) => templateConfig.printConfig);
  }

  @Selector([LabelPreviewState])
  public static getPrintTimeParameters(state: ILabelPreviewStateModel) {
    return state.printTimeParameters;
  }

  constructor(
    private store: Store,
    private labelPreviewSchedulerService: LabelPreviewJobSchedulerService,
    private blobToImageConverter: BlobToImageConverterService,
    private labelTemplateService: LabelTemplateService
  ) {
    this.subscribePreviewJobEvents();
  }

  public ngOnDestroy(): void {
    this.previewEventSubscription?.unsubscribe();
  }

  @Action(LoadTemplatePreviews)
  public loadTemplatePreviews(ctx: StateContext<ILabelPreviewStateModel>, action: LoadTemplatePreviews) {
    return this.labelTemplateService.getLabelTemplatesByVariantGroupAndArticleId(action.articleId, action.variantGroupId).pipe(
      map(async (response) => {
        const templates = response.templates;
        const results = [];

        for (let template of templates) {
          const printTimeParameters = this.getPrintTimeParameters(template.id, ctx);
          const jobId = await this.labelPreviewSchedulerService.schedulePreviewJob(response.variantSelectionDto.type, template, printTimeParameters, response.variantSelectionDto);
          const config: LabelTemplateConfiguration = {
            template: template,
            preview: {
              jobId: jobId,
              status: JobStatus.Created,
            },
            printConfig: {
              labelId: template.id,
              isSelected: false,
              targetPrinterId: null,
              quantity: 0,
              packagingQuantity: 0,
              labelDesignation: template.designation,
              productionDate: printTimeParameters.productionDate,
            },
          };

          results.push(config);
        }

        ctx.setState(patch({ labelTemplateConfiguration: results }));
        ctx.dispatch([new SetPrintTimeSchemaParameters(response.printTimeVariables), new GetPrinterSuggestions(templates.map((template) => template.id))]);

        return response;
      })
    );
  }

  @Action(CreateRawTemplatePreview)
  public async createRawTemplatePreview(ctx: StateContext<ILabelPreviewStateModel>, action: CreateRawTemplatePreview) {
    const printTimeParameters: TemplatePrintTimeParameters = this.getPrintTimeParameters(action.template.id, ctx);
    const jobId = await this.labelPreviewSchedulerService.schedulePreviewJob(JobType.RawTemplate, action.template, printTimeParameters, null);
    const config: LabelTemplateConfiguration = {
      template: action.template,
      preview: {
        jobId: jobId,
        status: JobStatus.Created,
      },
      printConfig: {
        labelId: action.template.id,
        isSelected: false,
        targetPrinterId: null,
        quantity: 0,
        packagingQuantity: 0,
        labelDesignation: action.template.designation,
        productionDate: printTimeParameters.productionDate,
      },
    };

    ctx.setState(patch({ labelTemplateConfiguration: this.insertOrUpdateLabelTemplateConfiguration(config) }));
  }

  @Action(UpdateProductionDate)
  public async updateProductionDate(ctx: StateContext<ILabelPreviewStateModel>, action: UpdateProductionDate) {
    const templateConfiguration = ctx.getState().labelTemplateConfiguration;
    const selectedVariant = action.selectedVariant;

    if (!templateConfiguration) {
      return;
    }

    const updateItems = templateConfiguration.map((_, index: number) =>
      updateItem<LabelTemplateConfiguration>(
        index,
        patch({
          printConfig: patch({ productionDate: action.productionDate }),
        })
      )
    );

    ctx.setState(
      patch({
        labelTemplateConfiguration: compose(...updateItems),
      })
    );

    for (var config of templateConfiguration) {
      const printTimeParameters = this.getPrintTimeParameters(config.template.id, ctx);
      const jobId = await this.labelPreviewSchedulerService.schedulePreviewJob(selectedVariant.type, config.template, printTimeParameters, selectedVariant);
      ctx.setState(
        patch({
          labelTemplateConfiguration: updateItem<LabelTemplateConfiguration>(
            (previousConfiguration) => previousConfiguration.template.id === config.template.id,
            patch({
              preview: patch({ jobId: jobId, status: JobStatus.Created }),
            })
          ),
        })
      );
    }
  }

  @Action(UpdatePrintTimeParameter)
  public async updatePrintTimeParameter(ctx: StateContext<ILabelPreviewStateModel>, action: UpdatePrintTimeParameter) {
    ctx.setState(
      patch({
        printTimeParameters: this.insertOrUpdateKeyValue(action),
      })
    );

    if (action.requiresPreviewReload) {
      const templateConfiguration = ctx.getState().labelTemplateConfiguration;
      const selectedVariant = action.selectedVariant;

      for (var config of templateConfiguration) {
        const printTimeParameters = this.getPrintTimeParameters(config.template.id, ctx);
        const jobId = await this.labelPreviewSchedulerService.schedulePreviewJob(selectedVariant.type, config.template, printTimeParameters, selectedVariant);
        ctx.setState(
          patch({
            printTimeParameters: this.insertOrUpdateKeyValue(action),
            labelTemplateConfiguration: updateItem<LabelTemplateConfiguration>(
              (labelTemplateConfiguration: LabelTemplateConfiguration) => labelTemplateConfiguration.template.id === config.template.id,
              patch({ preview: patch({ jobId: jobId, status: JobStatus.Created }) })
            ),
          })
        );
      }
    }
  }

  @Action(UpdateLabelPreview)
  public updatePreviewJob(ctx: StateContext<ILabelPreviewStateModel>, action: UpdateLabelPreview) {
    const previewImage = action.job.previewData != null ? this.blobToImageConverter.convertToImage(action.job.previewData) : null;

    ctx.setState(
      patch({
        labelTemplateConfiguration: updateItem<LabelTemplateConfiguration>(
          (previousConfiguration) => previousConfiguration.preview.jobId === action.job.jobId,
          patch({ preview: patch({ status: action.job.jobState, previewImage: previewImage }) })
        ),
      })
    );
  }

  @Action(UpdatePrintConfiguration)
  public async updatePrintConfiguration(ctx: StateContext<ILabelPreviewStateModel>, action: UpdatePrintConfiguration) {
    ctx.setState(
      patch({
        labelTemplateConfiguration: updateItem<LabelTemplateConfiguration>((previousConfiguration) => previousConfiguration.template.id === action.config.labelId, patch({ printConfig: patch(action.config) })),
      })
    );

    if (action.requiresPreviewReload) {
      const template = ctx.getState().labelTemplateConfiguration.find((config) => config.printConfig.labelId === action.config.labelId).template;
      const selectedVariant = action.selectedVariant;
      const printTimeParameters = this.getPrintTimeParameters(template.id, ctx);

      const jobId = await this.labelPreviewSchedulerService.schedulePreviewJob(selectedVariant.type, template, printTimeParameters, selectedVariant);

      ctx.setState(
        patch({
          labelTemplateConfiguration: updateItem<LabelTemplateConfiguration>(
            (previousConfiguration) => previousConfiguration.template.id === action.config.labelId,
            patch({
              printConfig: patch(action.config),
              preview: patch({ jobId: jobId, status: JobStatus.Created }),
            })
          ),
        })
      );
    }
  }

  @Action(ResetPreviews)
  public resetLabelPreviews({ patchState }: StateContext<ILabelPreviewStateModel>, _: ResetPreviews) {
    this.labelPreviewSchedulerService.silentTerminateAllJobs();
    patchState({ labelTemplateConfiguration: [], printTimeParameters: [] });
  }

  private insertOrUpdateKeyValue(printParameter: { key: string; value: string }) {
    return iif<{ key: string; value: string }[]>(
      (parameter) => parameter?.some((param) => param.key === printParameter.key),
      updateItem((parameter) => parameter.key === printParameter.key, patch(printParameter)),
      insertItem(printParameter)
    );
  }

  private insertOrUpdateLabelTemplateConfiguration(labelTemplatePreview: LabelTemplateConfiguration) {
    return iif<LabelTemplateConfiguration[]>(
      (templateConfigurations) => templateConfigurations?.some((templateConfig) => templateConfig.template.id === labelTemplatePreview.template.id),
      updateItem((templateConfig) => templateConfig.template.id === labelTemplatePreview.template.id, patch(labelTemplatePreview)),
      insertItem(labelTemplatePreview)
    );
  }

  private getPrintTimeParameters(labelId: string, ctx: StateContext<ILabelPreviewStateModel>): TemplatePrintTimeParameters {
    const packagingQuantity = ctx.getState().labelTemplateConfiguration?.find((cfg) => cfg.template.id === labelId)?.printConfig.packagingQuantity ?? 0;
    const productionDate = ctx.getState().labelTemplateConfiguration?.find((cfg) => cfg.template.id === labelId)?.printConfig.productionDate ?? moment().format('YYYY-MM-DD');

    let allPrintTimeParameters = this.store
      .selectSnapshot(PrintState.getPrintTimeParameters)
      ?.map((parameter) => parameter.name)
      ?.reduce((current, previous) => ({ ...current, [previous]: previous }), {});

    const printTimeParameters = ctx.getState().printTimeParameters?.reduce((obj, parameter) => ({ ...obj, [parameter.key]: parameter.value }), {} as { [key: string]: string }) ?? {};

    allPrintTimeParameters = { ...allPrintTimeParameters, ...printTimeParameters };

    for (const key of Object.keys(allPrintTimeParameters)) {
      if (!printTimeParameters[key]) {
        printTimeParameters[key] = '';
      }
    }

    return { packagingQuantity: packagingQuantity, printTimeParameters: printTimeParameters, productionDate: productionDate } as TemplatePrintTimeParameters;
  }

  private subscribePreviewJobEvents() {
    this.previewEventSubscription = this.labelPreviewSchedulerService.previewJobStateChanged?.subscribe((job) => this.store.dispatch(new UpdateLabelPreview(job)));
  }
}
