import { Directive, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, ActionType, ofActionCompleted, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as masterDictionary from 'src/assets/i18n/de.json';
import { TranslationService } from '../transloco-root/services/translation.service';
import { NestedPropertyOf } from '../transloco-root/types/nested-key-of.type';
import { ComponentBaseService } from './component.base.service';
import { StandardListviewComponent } from './components/standard-components/standard-listview/standard-listview.component';
import { ofActionErroredDetail } from './error-handling';
import { ILabelManagerProblemDetails } from './interfaces/label-manager-problem-details.interface';
import { NotificationsService } from './services/notifications-service/notifications.service';
import { SetSearchText } from './state/application.actions';

export interface IOnRouteParamsChanged {
  routeParamsChanged(params: Params): void;
}

export interface IOnRouteQueryParamsChanged {
  routeQueryParamsChanged(params: Params): void;
}

export interface IAllowRealTimeUpdates {
  startRealTimeUpdates(): void;
  stopRealTimeUpdates(): void;
}

@Directive()
export abstract class ComponentBaseDirective implements OnDestroy {
  @ViewChild(StandardListviewComponent, { static: true }) protected standardListviewComponent: StandardListviewComponent;
  public mainLoaderId = 'main';
  public spinnerService: NgxSpinnerService;

  protected subscription = new Subscription();
  protected actions$: Actions;
  public store: Store;
  public headerText: string;
  protected isPageFiltered = false;
  protected orderBy: string;
  protected orderDirection: string;

  private searchText: string;
  private loaderIds: string[] = [];

  protected notificationsService: NotificationsService;
  protected translationService: TranslationService;

  constructor(componentBaseService: ComponentBaseService) {
    this.store = componentBaseService.store;
    this.actions$ = componentBaseService.actions$;
    this.notificationsService = componentBaseService.notificationsService;
    this.spinnerService = componentBaseService.spinnerService;
    this.translationService = componentBaseService.translationService;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected addRouteParamsChanged(route: ActivatedRoute, onlyOnce = true) {
    const routeParams = route.params;
    if (onlyOnce) {
      routeParams.pipe(take(1));
    }
    this.subscribe(routeParams, (params) => {
      const self = this as unknown as IOnRouteParamsChanged;
      if (self.routeParamsChanged != null) {
        self.routeParamsChanged(params);
      }
    });
  }

  protected addRouteQueryParamsChanged(route: ActivatedRoute, onlyOnce = true) {
    const routeParams = route.queryParams;
    if (onlyOnce) {
      routeParams.pipe(take(1));
    }
    this.subscribe(routeParams, (params) => {
      const self = this as unknown as IOnRouteQueryParamsChanged;
      if (self.routeQueryParamsChanged != null) {
        self.routeQueryParamsChanged(params);
      }
    });
  }

  protected subscribe<T>(observable: Observable<T>, callback: (result: T) => void) {
    this.subscription.add(observable?.subscribe(callback));
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  protected select<T>(selector: (state: any, ...states: any[]) => T, updateFunc: (newValue: T) => void) {
    this.subscription.add(this.store.select(selector).subscribe((newValue) => updateFunc(newValue)));
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  protected ofActionSuccessful(callback: (state?: any) => void, ...allowedTypes: ActionType[]) {
    this.subscribe(this.actions$.pipe(ofActionSuccessful(...allowedTypes)), (state) => callback(state));
  }

  protected ofActionCompleted(callback: () => void, ...allowedTypes: ActionType[]) {
    this.ofActionCompletedInternal(callback, allowedTypes);
  }

  protected addSuccessNotification(message: NestedPropertyOf<typeof masterDictionary>, ...allowedTypes: ActionType[]): void;
  protected addSuccessNotification(message: NestedPropertyOf<typeof masterDictionary>, title: NestedPropertyOf<typeof masterDictionary>, ...allowedTypes: ActionType[]): void;
  protected addSuccessNotification(message: NestedPropertyOf<typeof masterDictionary>, title: string | ActionType, ...allowedTypes: ActionType[]): void {
    if (typeof title === 'string') {
      this.ofActionSuccessful(
        () => {
          const translatedMessage = this.translationService.translate(message);
          this.notificationsService.plainSuccess(translatedMessage, title);
        },
        ...allowedTypes
      );
    } else {
      this.ofActionSuccessful(
        () => {
          const translatedMessage = this.translationService.translate(message);
          this.notificationsService.plainSuccess(translatedMessage);
        },
        title,
        ...allowedTypes
      );
    }
  }

  protected ofActionErrored(callback: (result: { action: ActionType; error: ILabelManagerProblemDetails }) => void, ...allowedTypes: ActionType[]) {
    this.spinnerService.hide();
    this.subscribe(this.actions$.pipe(ofActionErroredDetail(...allowedTypes)), (result) => callback(result));
  }

  protected addSuccessNotificationCallback(text: string, callback: () => void, ...allowedTypes: ActionType[]) {
    this.ofActionSuccessful(
      () => {
        this.notificationsService.plainSuccess(text);
        callback();
      },
      ...allowedTypes
    );
  }

  protected addErrorNotification(text: NestedPropertyOf<typeof masterDictionary>, ...allowedTypes: ActionType[]): void {
    this.ofActionErrored(
      (result) => {
        this.notificationsService.error(text, result.error);
      },
      ...allowedTypes
    );
  }

  protected configureHeaderText(title: string, searchText: string) {
    if (this.isPageFiltered) {
      this.headerText = `${title} nach ${searchText} gefiltert`;
    } else {
      this.headerText = `${title}`;
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public pageChanged(event: { page: number; pageSize: number }, action: any) {
    this.store.dispatch(new action(event.page, event.pageSize, this.orderBy, this.orderDirection, this.searchText));
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public sortChanged(event: { orderBy: string; orderDirection: string }, action: any) {
    this.orderBy = event.orderBy;
    this.orderDirection = event.orderDirection;
    this.store.dispatch(new action(this.standardListviewComponent.currentPage, this.standardListviewComponent.DefaultPaginationSize, event.orderBy, event.orderDirection, this.searchText));
  }

  public changeSearchText(searchText: string) {
    this.searchText = searchText;
    this.store.dispatch(new SetSearchText(this.searchText));
  }

  public getSearchText(): string {
    return this.searchText;
  }

  private ofActionCompletedInternal(callback: () => void, allowedTypes: ActionType[], trackedActions: object[] = undefined) {
    this.subscribe(this.actions$.pipe(ofActionCompleted(...allowedTypes)), (a) => {
      const idx = trackedActions?.indexOf(a.action);
      const isTrackedAction = idx >= 0;
      if (trackedActions && !isTrackedAction) {
        // if action tracking is activated.
        return;
      }
      // remove completed action from tracking.
      trackedActions?.splice(idx, 1);

      return callback();
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private ofActionDispatchedInternal(callback: (actionContext: any) => void, allowedTypes: ActionType[], trackedActions: object[] = undefined) {
    this.subscribe(this.actions$.pipe(ofActionDispatched(...allowedTypes)), (a) => {
      trackedActions && trackedActions.push(a);
      callback(a);
    });
  }

  protected addToMainLoader(...allowedTypes: ActionType[]) {
    this.addLoader('main', ...allowedTypes);
  }

  protected addLoader(loaderName?: string, ...allowedTypes: ActionType[]) {
    this.loaderIds.push(loaderName);
    const dispatchedActions = [] as Array<object>;
    this.ofActionDispatchedInternal(
      () => {
        this.spinnerService.show(loaderName);
      },
      allowedTypes,
      dispatchedActions
    );
    this.ofActionCompletedInternal(() => this.spinnerService.hide(loaderName), allowedTypes, dispatchedActions);
  }
}
