import { Injectable } from '@angular/core';
import { AttributeValueDto, SchemaAttributeDto } from '@api/model/models';

@Injectable({
  providedIn: 'root',
})
export class AttributeHelperService {
  public mergeArticleAndSchemaAttributes(articleAttributes: AttributeValueDto[], schemaAttributes: SchemaAttributeDto[]): AttributeValueDto[] {
    if (!articleAttributes || !schemaAttributes) {
      return [];
    }

    const sortedAttributes = [...schemaAttributes]
      .sort((variantAttributeValueA, variantAttributeValueB) => variantAttributeValueA.sortNumber - variantAttributeValueB.sortNumber)
      .map((schemaAttribute) => ({ dataType: schemaAttribute.dataType, key: schemaAttribute.name, value: null, sortNumber: schemaAttribute.sortNumber }) as AttributeValueDto);

    articleAttributes.forEach((articleAttribute) => {
      const indexInSortedAttributes = sortedAttributes.findIndex((attribute) => attribute.key === articleAttribute.key);
      if (indexInSortedAttributes > -1) {
        sortedAttributes[indexInSortedAttributes].value = articleAttribute.value;
      } else {
        sortedAttributes.push({ ...articleAttribute, sortNumber: null });
      }
    });

    return sortedAttributes;
  }
}
