import { LabelTemplateDto, VariantSelectionDto } from '@api';
import { ActivePreviewJob } from '../models/activePreviewJob';
import { PrintConfigurationItem } from '../models/printConfigurationItem';

export class LoadTemplatePreviews {
  public static readonly type = '[LabelPreview] Load Template Previews';

  constructor(
    public articleId: string,
    public variantGroupId: string
  ) {}
}

export class CreateRawTemplatePreview {
  public static readonly type = '[LabelPreview] Create RawTemplate preview for Template';
  constructor(public template: LabelTemplateDto) {}
}

export class ResetPreviews {
  public static readonly type = '[LabelPreview] Reset Label Templates';

  constructor() {}
}

export class UpdatePrintConfiguration {
  public static readonly type = '[LabelPreview] Update Print Label Configuration';

  constructor(
    public selectedVariant: VariantSelectionDto,
    public config: PrintConfigurationItem,
    public requiresPreviewReload: boolean
  ) {}
}

export class UpdatePrintTimeParameter {
  public static readonly type = '[LabelPreview] Update Print Time Parameter(s)';

  constructor(
    public selectedVariant: VariantSelectionDto,
    public key: string,
    public value: string,
    public requiresPreviewReload: boolean
  ) {}
}

export class UpdateProductionDate {
  public static readonly type = '[LabelPreview] Update Production Date';

  constructor(
    public selectedVariant: VariantSelectionDto,
    public productionDate: string
  ) {}
}

export class UpdateLabelPreview {
  public static readonly type = '[LabelPreview] Update Label Preview';

  constructor(public job: ActivePreviewJob) {}
}
