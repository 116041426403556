import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class BlobToImageConverterService {
  constructor(private sanitizer: DomSanitizer) {}

  public convertToImage(imageBytes: string): SafeUrl {
    if (imageBytes == null) {
      return;
    }

    let objectURL = 'data:image;base64,' + imageBytes;
    let safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    return safeUrl;
  }

  public blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
