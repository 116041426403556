import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthorizationService } from '../shared/services/authorization/authorization.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthorizationService) {}

  public canActivate(): boolean | Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      return true;
    }

    return this.authService.login();
  }
}
