import { JobStatus } from '@api';
import { Subscription } from 'rxjs';

export class ActivePreviewJob {
  public labelId: string;
  public jobState: JobStatus;
  public previewData: string;
  public jobId: string;
  public jobSubscription: Subscription;
  public retryCount: number;

  constructor(labelId: string, jobState: JobStatus, previewData: string, jobId: string, retryCount: number) {
    this.labelId = labelId;
    this.jobState = jobState;
    this.previewData = previewData;
    this.jobId = jobId;
    this.retryCount = retryCount;
  }
}
