import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interpolateString',
})
export class InterpolateStringPipe implements PipeTransform {
  public transform(value: string, params?: { [key: string]: string }): string {
    if ((value ?? '').trim() === '') {
      return '';
    }

    const pattern = /{{(.*?)}}/g;
    const matches = [...value.matchAll(pattern)];
    const parameterKeys = Object.keys(params ?? {});

    for (const key of parameterKeys) {
      const existingMatch = matches.find((match) => match[1] === key);

      if (!existingMatch) {
        continue;
      }

      const valueOfParameter = params[key];
      value = value.replace(existingMatch[0], valueOfParameter);
    }

    return value;
  }
}
