import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPaginationModule } from '@m3rlin94/ngx-pagination';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgWizardModule } from 'ng-wizard3';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AttributeInputComponent } from '../portal/controls/attributes/attribute-input/attribute-input.component';
import { SchemaParameterAssignComponent } from '../portal/controls/attributes/schema-parameters/schema-parameter-assign/schema-parameter-assign.component';
import { SchemaParameterEditComponent } from '../portal/controls/attributes/schema-parameters/schema-parameter-edit/schema-parameter-edit.component';
import { SchemaParameterViewComponent } from '../portal/controls/attributes/schema-parameters/schema-parameter-view/schema-parameter-view.component';
import { TranslocoRootModule } from '../transloco-root/transloco-root.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { EditableLabelComponent } from './components/editable-label/editable-label.component';
import { SectionComponent } from './components/section/section.component';
import { PrintApprovalBadgeComponent } from './components/standard-components/print-approval-badge/print-approval-badge.component';
import { StandardComponentsModule } from './components/standard-components/standard-components.module';
import { DateTemplateComponent } from './components/standard-components/standard-listview/templates/date-template/date-template.component';
import { VariantSelectTemplateComponent } from './components/standard-components/standard-listview/templates/variant-template/variant-select-template.component';
import { StatusBadgeComponent } from './components/standard-components/status-badge/status-badge.component';
import { UsedLabelTemplatesComponent } from './components/used-label-templates/used-label-templates.component';
import { DebounceInputDirective } from './directives/debounce-input/debounce-input.directive';
import { DisabledPermissionDirective } from './directives/disabled-permission/disabled-permission.directive';
import { HasPermissionDirective } from './directives/has-permission/has-permission.directive';
import { LetDirective } from './directives/ng-let/ng-let.directive';
import { AttributeHelperService } from './helpers/attribute-helper.service';
import { BlobToImageConverterService } from './helpers/blob-to-image-converter.service';
import { CaptionBasedOnDataTypePipe } from './pipes/caption-based-on-data-type.pipe';
import { InterpolateStringPipe } from './pipes/interpolate-string.pipe';
import { LabelTypePipe } from './pipes/label-type.pipe';
import { DeactivationGuard } from './services/deactivation-guard/deactivation-guard.service';
import { CustomToastComponent } from './services/notifications-service/custom-toastr/custom-toast.component';

@NgModule({
  declarations: [
    CustomToastComponent,
    StatusBadgeComponent,
    PrintApprovalBadgeComponent,
    DateTemplateComponent,
    VariantSelectTemplateComponent,
    DynamicInputComponent,
    SchemaParameterEditComponent,
    SchemaParameterAssignComponent,
    AttributeInputComponent,
    SchemaParameterViewComponent,
    DebounceInputDirective,
    HasPermissionDirective,
    DisabledPermissionDirective,
    SectionComponent,
    LetDirective,
    UsedLabelTemplatesComponent,
    EditableLabelComponent,
    ConfirmationDialogComponent,
    LabelTypePipe,
    CaptionBasedOnDataTypePipe,
    InterpolateStringPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    NgbTooltipModule,
    NgSelectModule,
    NgbDropdownModule,
    DragDropModule,
    StandardComponentsModule,
    TranslocoRootModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxDatatableModule,
    CustomToastComponent,
    DynamicInputComponent,
    NgbTooltipModule,
    StatusBadgeComponent,
    PrintApprovalBadgeComponent,
    DateTemplateComponent,
    VariantSelectTemplateComponent,
    NgbTooltipModule,
    NgSelectModule,
    NgbDropdownModule,
    SchemaParameterEditComponent,
    SchemaParameterAssignComponent,
    AttributeInputComponent,
    SchemaParameterViewComponent,
    NgWizardModule,
    DebounceInputDirective,
    HasPermissionDirective,
    DisabledPermissionDirective,
    DragDropModule,
    HasPermissionDirective,
    StandardComponentsModule,
    SectionComponent,
    LetDirective,
    UsedLabelTemplatesComponent,
    EditableLabelComponent,
    LabelTypePipe,
    TranslocoRootModule,
    CaptionBasedOnDataTypePipe,
    InterpolateStringPipe,
  ],
  providers: [DeactivationGuard],
})
export class SharedModule {
  public static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [BlobToImageConverterService, AttributeHelperService],
    };
  }
}
