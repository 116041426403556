import { EventEmitter, Injectable } from '@angular/core';
import { JobStatus, JobType, LabelPreviewService, LabelTemplateDto, PreviewJobResult, VariantSelectionDto } from '@api';
import { lastValueFrom, timer } from 'rxjs';
import { retry, share, switchMap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/services/notifications-service/notifications.service';
import { PrintingConstants } from '../../printing/print-consts';
import { TemplatePrintTimeParameters } from '../interfaces/template-print-time-parameters.interface';
import { ActivePreviewJob } from '../models/activePreviewJob';

@Injectable({
  providedIn: 'root',
})
export class LabelPreviewJobSchedulerService {
  private activeJobs: ActivePreviewJob[] = [];

  public previewJobStateChanged = new EventEmitter<ActivePreviewJob>();

  constructor(
    private labelPreviewService: LabelPreviewService,
    private notificationService: NotificationsService
  ) {}

  public async schedulePreviewJob(jobType: JobType, label: LabelTemplateDto, printTimeParameters: TemplatePrintTimeParameters, selectedVariant: VariantSelectionDto) {
    this.abortExistingJob(label.id);

    var identifier = jobType === JobType.Variant ? selectedVariant.variantId : selectedVariant?.articleId;

    var jobId = await lastValueFrom(this.labelPreviewService.createPreviewJob(label.id, printTimeParameters.printTimeParameters, jobType, printTimeParameters.packagingQuantity, identifier, printTimeParameters.productionDate));
    const job = new ActivePreviewJob(label.id, JobStatus.Scheduled, '', jobId, 0);
    const pollPreviewSubscription = timer(3500, 1000)
      .pipe(
        switchMap(() => this.labelPreviewService.getPreview(jobId)),
        retry(),
        share()
      )
      .subscribe(
        (previewJobResult) => this.handlePreviewJobEvent(previewJobResult, job),
        (_) => this.abortExistingJob(label.id)
      );

    job.jobSubscription = pollPreviewSubscription;

    this.activeJobs.push(job);

    return jobId;
  }

  public silentTerminateAllJobs() {
    var ids = this.activeJobs.map((job) => job.labelId);

    for (var id of ids) {
      this.abortExistingJob(id);
    }
  }

  private handlePreviewJobEvent(result: PreviewJobResult, job: ActivePreviewJob): void {
    job.jobState = result.jobStatus;

    if (result.jobStatus == JobStatus.Finished || result.jobStatus == JobStatus.Aborted) {
      job.previewData = result?.preview;
      this.previewJobStateChanged?.emit(job);
      this.removeJobFromActiveJobs(job);
    }

    if (job.retryCount >= PrintingConstants.previewJobMaxRetries) {
      job.jobState = JobStatus.Aborted;
      this.previewJobStateChanged?.emit(job);

      this.abortExistingJob(job.labelId);
      this.notificationService.error('labelPreview.previewFailed');
    }

    job.retryCount++;
  }

  private async abortExistingJob(labelId: string) {
    const job = this.activeJobs.find((job) => job.labelId === labelId);

    if (job) {
      this.removeJobFromActiveJobs(job);
      await lastValueFrom(this.labelPreviewService.deletePreviewJob(job.jobId));
    }
  }

  private removeJobFromActiveJobs(job: ActivePreviewJob) {
    job.jobSubscription.unsubscribe();

    var index = this.activeJobs.indexOf(job);
    this.activeJobs.splice(index, 1);
  }
}
