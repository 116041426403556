/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type JobStatus = 'Created' | 'Scheduled' | 'Printing' | 'Finished' | 'Aborted' | 'Unknown';

export const JobStatus = {
    Created: 'Created' as JobStatus,
    Scheduled: 'Scheduled' as JobStatus,
    Printing: 'Printing' as JobStatus,
    Finished: 'Finished' as JobStatus,
    Aborted: 'Aborted' as JobStatus,
    Unknown: 'Unknown' as JobStatus
};

