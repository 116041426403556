import { DestroyRef, inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter, Subscription, tap } from 'rxjs';
import { authConfig } from 'src/app/auth/config/authConfig';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private subscription = new Subscription();
  private destroyRef$ = inject(DestroyRef);
  private oauthService = inject(OAuthService);

  constructor() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.subscribeEvents();
    this.onDestroy();
  }

  public logout() {
    this.oauthService.revokeTokenAndLogout();
  }

  public isAuthenticated(): boolean {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      return true;
    }

    return false;
  }

  public getClaims() {
    return this.oauthService.getIdentityClaims()?.['name'] ?? '';
  }

  public login() {
    const promise = new Promise<boolean>((resolve) => {
      this.oauthService
        .loadDiscoveryDocumentAndLogin()
        .then((result: boolean) => resolve(result))
        .catch(() => this.logout());

      this.oauthService.setupAutomaticSilentRefresh();
    });

    return promise;
  }

  private subscribeEvents() {
    this.subscription.add(
      this.oauthService.events
        .pipe(
          filter((event) => event.type === 'invalid_nonce_in_state'),
          tap(() => {
            // Re-Authenticate user when token is invalid in new browser tabs
            this.oauthService.initImplicitFlow();
          })
        )
        .subscribe()
    );
  }

  private onDestroy() {
    this.destroyRef$.onDestroy(() => this.subscription.unsubscribe());
  }
}
