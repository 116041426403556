import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, tap } from 'rxjs';
import { ComponentBaseDirective } from './shared/component.base';
import { ComponentBaseService } from './shared/component.base.service';
import { ApplicationState } from './shared/state/application.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ComponentBaseDirective implements OnInit {
  public tenantName$: Observable<string> = this.store.select(ApplicationState.getTenantName);

  constructor(
    public componentBaseService: ComponentBaseService,
    public title: Title
  ) {
    super(componentBaseService);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.tenantName$
        .pipe(
          tap((tenantName) => {
            this.title.setTitle(tenantName);
          })
        )
        .subscribe()
    );
  }
}
