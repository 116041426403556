import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Link } from './models/Link';

@Component({
  selector: 'app-standard-dropdown-card',
  templateUrl: './standard-dropdown-card.component.html',
  styleUrls: ['./standard-dropdown-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardDropdownCardComponent {
  @Input() public resultCount: number;
  @Input() public cardTitle: string;
  @Input() public cardContent: string;
  @Input() public links: Array<Link>;
}
