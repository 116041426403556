import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApprobationService } from './api/approbation.service';
import { AttributeGroupService } from './api/attributeGroup.service';
import { LabelPreviewService } from './api/labelPreview.service';
import { LabelTemplateService } from './api/labelTemplate.service';
import { MasterArticleService } from './api/masterArticle.service';
import { MasterSchemaService } from './api/masterSchema.service';
import { PrinterService } from './api/printer.service';
import { SupportIssueService } from './api/supportIssue.service';
import { TenantService } from './api/tenant.service';
import { UploadService } from './api/upload.service';
import { VariantService } from './api/variant.service';
import { VariantGroupService } from './api/variantGroup.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
