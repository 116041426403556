import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Directive({ selector: '[appHasPermission]' })
export class HasPermissionDirective implements OnInit {
  @Input('appHasPermission') public requiredPermissions: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private permissionsService: PermissionsService
  ) {}

  public ngOnInit() {
    const permissions = this.requiredPermissions.split(',').map((p) => p.trim());

    if (this.permissionsService != null && this.permissionsService.hasPermissions(permissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
