import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { SearchResultDto } from '@api/model/searchResultDto';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { masterDictionary } from 'src/assets/i18n';
import { environment } from 'src/environments/environment';
import { ComponentBaseDirective } from '../shared/component.base';
import { ComponentBaseService } from '../shared/component.base.service';
import { Link } from '../shared/components/standard-components/standard-dropdown-card/models/Link';
import { permissions } from '../shared/constants/permission.constants';
import { IRouterData } from '../shared/interfaces/router-data.interface';
import { ISearchResult } from '../shared/interfaces/search-result.interface';
import { PermissionsService } from '../shared/services/permissions/permissions.service';
import { Logout, Search } from '../shared/state/application.actions';
import { ApplicationState } from '../shared/state/application.state';
import { NestedPropertyOf } from '../transloco-root/types/nested-key-of.type';
import { ReportSupportIssueService } from './support-issues/report-support-issue.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportSupportIssueService],
})
export class PortalComponent extends ComponentBaseDirective implements OnInit, OnDestroy {
  @ViewChild('searchDropdown', { static: false }) public searchDropdown: NgbDropdown;
  public isUserLoggedIn$: Observable<boolean> = this.store.select(ApplicationState.isUserLoggedIn);
  public username$: Observable<string> = this.store.select(ApplicationState.getUsername);
  public searchResult$: Observable<SearchResultDto> = this.store.select(ApplicationState.getSearchResult);
  public searchText$: Observable<string> = this.store.select(ApplicationState.getSearchText);
  public tenantName$: Observable<string> = this.store.select(ApplicationState.getTenantName);

  @HostBinding('class.sb-nav-fixed') private fixedNavBar = true;
  @HostBinding('class.sb-sidenav-toggled') private isSidebarToggled = false;

  public get currentYear() {
    return new Date().getFullYear();
  }

  public version = environment.version;
  public searchTerm$: Subject<string> = new Subject();
  public searchResults: ISearchResult[] = [];
  public searchInputText: string;
  public hasSearchResults = true;
  public LabelTemplatesSearchLink: Link;
  public ArticlesSearchLink: Link;
  public VariantGroupsSearchLink: Link;
  public SchemasSearchLink: Link;
  public VariantsSearchLink: Link;
  public ApprobationsSearchLink: Link;
  public PrintersSearchLink: Link;
  public SupportTicketsSearchLink: Link;
  public routerData: IRouterData;
  public permissions = permissions;

  public language$ = this.translationService.languageChanges$;

  constructor(
    public componentBaseService: ComponentBaseService,
    public cdRef: ChangeDetectorRef,
    private router: Router,
    private reportSupportIssueService: ReportSupportIssueService,
    private permissionService: PermissionsService
  ) {
    super(componentBaseService);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.searchText$
        .pipe(
          tap((searchText: string) => {
            this.searchInputText = searchText ?? '';
          }),
          filter((searchText) => searchText != null)
        )
        .subscribe((searchText) => {
          this.changeSearchText(searchText);
          this.updateLinks(searchText);
        })
    );

    this.getSearchResult();

    this.subscription.add(
      this.searchTerm$
        .pipe(
          filter((searchText) => searchText.length > 2),
          debounceTime(1000),
          switchMap((searchText: string) => {
            this.searchDropdown.open();
            return this.store.dispatch(new Search(searchText));
          })
        )
        .subscribe(() => this.cdRef.markForCheck())
    );

    this.getRouterData();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.reportSupportIssueService.ngOnDestroy();
  }

  public logout(): void {
    this.store.dispatch(new Logout());
  }

  public search(): void {
    const searchText = this.getSearchText();
    this.searchTerm$.next(searchText);
    this.store.dispatch(new Search(searchText));
  }

  public onSearchInputChanged(searchText: string) {
    this.changeSearchText(searchText);
    this.updateLinks(searchText);
  }

  public setNextLanguage() {
    const languages = this.translationService.getAvailableLanguages();
    const index = languages.findIndex((language) => language === this.translationService.getActiveLanguage());
    const nextLanguage = languages[index + 1] ?? languages[0];
    this.translationService.setActiveLanguage(nextLanguage as string);
  }

  public toggleSidebar() {
    this.isSidebarToggled = !this.isSidebarToggled;
  }

  public updateLinks(searchText: string) {
    const searchTextParams: { [key: string]: string } = { search: searchText };

    this.LabelTemplatesSearchLink = { keyName: 'labelTemplate.plural', routerLink: './labeltemplates', queryParams: searchTextParams } as Link;
    this.ArticlesSearchLink = { keyName: 'article.plural', routerLink: './articles', queryParams: searchTextParams } as Link;
    this.VariantGroupsSearchLink = { keyName: 'variantGroup.plural', routerLink: './variantgroups', queryParams: searchTextParams } as Link;
    this.SchemasSearchLink = { keyName: 'masterSchema.plural', routerLink: './schemes', queryParams: searchTextParams } as Link;
    this.VariantsSearchLink = { keyName: 'variant.plural', routerLink: './variantgroups/variants', queryParams: searchTextParams } as Link;
    this.ApprobationsSearchLink = { keyName: 'approbation.plural', routerLink: './approbations', queryParams: searchTextParams } as Link;
    this.PrintersSearchLink = { keyName: 'printer.plural', routerLink: './printers', queryParams: searchTextParams } as Link;
    this.SupportTicketsSearchLink = { keyName: 'supportTickets.plural', routerLink: './supportIssues', queryParams: searchTextParams } as Link;
  }

  public async openReportSupportIssue() {
    await this.reportSupportIssueService.open(this.routerData);
  }

  public onRouterOutletActivated(activatedComponent: Component): void {
    this.routerData = { ...this.routerData, component: activatedComponent };
  }

  private getRouterData() {
    this.subscribe(this.router.events.pipe(filter((event) => event instanceof ActivationStart)), (event: ActivationStart) => {
      this.routerData = event.snapshot.data as IRouterData;
    });
  }

  private getSearchResult() {
    this.subscription.add(
      this.searchResult$.pipe(filter((searchResult) => searchResult != null)).subscribe(async (searchResult) => {
        this.searchResults = [];
        const searchResultKeys = Object.keys(searchResult);

        for (const key of searchResultKeys) {
          switch (key) {
            case 'articlesCount':
              this.addItemToSearchResult('article.plural', searchResult[key], 'portal.searchResultMessages.article', this.ArticlesSearchLink, ['MasterData.Read']);
              break;
            case 'labelTemplatesCount':
              this.addItemToSearchResult('labelTemplate.plural', searchResult[key], 'portal.searchResultMessages.labelTemplate', this.LabelTemplatesSearchLink, ['MasterData.Read']);
              break;
            case 'schemasCount':
              this.addItemToSearchResult('masterSchema.plural', searchResult[key], 'portal.searchResultMessages.masterSchema', this.SchemasSearchLink, ['MasterData.Read']);
              break;
            case 'variantGroupsCount':
              this.addItemToSearchResult('variantGroup.plural', searchResult[key], 'portal.searchResultMessages.variantGroup', this.VariantGroupsSearchLink, ['MasterData.Read']);
              break;
            case 'variantsCount':
              this.addItemToSearchResult('variant.plural', searchResult[key], 'portal.searchResultMessages.variant', this.VariantsSearchLink, ['MasterData.Read']);
              break;
            case 'approbationsCount':
              this.addItemToSearchResult('approbation.plural', searchResult[key], 'portal.searchResultMessages.approbation', this.ApprobationsSearchLink, ['MasterData.Read']);
              break;
            case 'printersCount':
              this.addItemToSearchResult('printer.plural', searchResult[key], 'portal.searchResultMessages.printer', this.PrintersSearchLink, ['PrinterManagement.Read']);
              break;
            case 'supportIssuesCount':
              this.addItemToSearchResult('supportTickets.plural', searchResult[key], 'portal.searchResultMessages.supportTicket', this.SupportTicketsSearchLink, ['SupportTickets.Read']);
              break;
          }
        }

        this.hasSearchResults = this.searchResults.some((entity) => entity.count > 0 && entity.hasPermission);
      })
    );
  }

  private addItemToSearchResult(title: NestedPropertyOf<typeof masterDictionary>, count: number, message: NestedPropertyOf<typeof masterDictionary>, link: Link, permissions: string[]) {
    this.searchResults.push({
      title: this.translationService.translate(title),
      count,
      message: this.translationService.translate(message, { count: count }),
      link: link,
      hasPermission: this.permissionService.hasPermissions(permissions),
    });
  }
}
