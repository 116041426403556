import { Injectable, OnDestroy } from '@angular/core';
import { CreateSupportIssueRequest } from '@api/model/createSupportIssueRequest';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { IRouterData } from 'src/app/shared/interfaces/router-data.interface';
import { NotificationsService } from 'src/app/shared/services/notifications-service/notifications.service';
import { CreateSupportIssueComponent } from './create-support-issue/create-support-issue.component';
import { IReportSupportIssue } from './report-support-issue.interface';
import { CreateSupportIssue } from './state/support-issues.actions';

@Injectable()
export class ReportSupportIssueService implements OnDestroy {
  private modalRef: NgbModalRef;
  private subscription = new Subscription();

  constructor(
    private ngbModalService: NgbModal,
    private store: Store,
    private notificationsService: NotificationsService
  ) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async open(routerData: IRouterData) {
    try {
      this.ngbModalService.dismissAll();
      this.modalRef = this.ngbModalService.open(CreateSupportIssueComponent);
      const confirmationModalComponent = this.modalRef.componentInstance as CreateSupportIssueComponent;

      const componentToGetDiagnosticData = routerData.component as IReportSupportIssue;
      if (componentToGetDiagnosticData) {
        confirmationModalComponent.diagnosticData = componentToGetDiagnosticData.getDiagnosticData();
      }

      const result = await this.modalRef.result;
      if (result && result !== 'close') {
        this.createSupportIssue(result);
      }
    } catch (error) {
      // If user clicks outside the opened modal dialog, an error occours in console because of unhandled promise
    }
  }

  private createSupportIssue(createSupportIssueRequest: CreateSupportIssueRequest) {
    this.subscription.add(
      this.store.dispatch(new CreateSupportIssue({ ...createSupportIssueRequest, diagnosticData: JSON.stringify(createSupportIssueRequest.diagnosticData) })).subscribe(
        () => {
          this.notificationsService.success('supportIssue.views.create.notifications.createSuportIssueSucceeded');
        },
        (error) => {
          this.notificationsService.error('supportIssue.views.create.notifications.createSuportIssueFailed', error);
        }
      )
    );
  }
}
