import { HttpErrorResponse } from '@angular/common/http';
import { ActionCompletion, ActionType, ofActionCompleted } from '@ngxs/store';
import { ActionContext } from '@ngxs/store/src/actions-stream';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ILabelManagerProblemDetails } from './interfaces/label-manager-problem-details.interface';

export const ofActionErroredDetail =
  (...action: ActionType[]) =>
  (source: Observable<ActionContext<typeof action>>) =>
    source.pipe(
      ofActionCompleted(...action),
      filter((completion: ActionCompletion) => !!completion.result.error && completion.result.error instanceof HttpErrorResponse),
      map((completion) => {
        const httpError = completion.result.error as HttpErrorResponse;
        const problemDetails = httpError.error as ILabelManagerProblemDetails;

        problemDetails.extensions = {
          ...problemDetails.extensions,
          correlationId: httpError?.headers?.get('x-correlation-id'),
        };

        return {
          action: completion.action as ActionType,
          error: problemDetails,
        };
      })
    );
