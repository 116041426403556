import { AbstractControl, FormControlDirective, NgControl } from '@angular/forms';

export const isRequired = (control: NgControl) => {
  const validatorFunction = (control as FormControlDirective)?.form?.validator;
  if (validatorFunction == null) {
    return false;
  }

  const validators = validatorFunction({} as AbstractControl);
  return validators?.required === true;
};
