import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from 'src/app/shared/services/permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private permissionService: PermissionsService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let permissions = route?.data?.permissions ?? [];
    var hasPermission = this.permissionService.hasPermissions(permissions);
    if (!hasPermission) {
      this.router.navigateByUrl('/error/403');
      return false;
    }

    return true;
  }
}
