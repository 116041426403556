import { SearchResultDto, UserTenantInformation } from '@api';

export interface IApplicationStateModel {
  isUserLoggedIn: boolean;
  username: string;
  searchResult: SearchResultDto;
  searchText: string;
  userTenantInformation: UserTenantInformation;
}

export const applicationStateModelDefault = {
  name: 'application',
  isUserLoggedIn: false,
  username: '',
  searchResult: {} as SearchResultDto,
  searchText: '',
  userTenantInformation: null as UserTenantInformation,
};
