import { UserTenantInformation } from '@api';

export class InitOAuth {
  public static readonly type = '[Application] Init OAuth';

  constructor(public userTenantInformation: UserTenantInformation) {}
}

export class Logout {
  public static readonly type = '[Application] Logout';

  constructor() {}
}

export class Search {
  public static readonly type = '[Application] Search';

  constructor(public searchText: string) {}
}

export class SetSearchText {
  public static readonly type = '[Application] Set Search Text';

  constructor(public searchText: string) {}
}
