import { CreateSupportIssueRequest, UpdateSupportIssueRequest } from '@api';
import { DefaultPaginationSize } from 'src/app/shared/paginationConstants';

export class GetSupportIssues {
  public static readonly type = '[Support Issues] Get Support Issues';

  constructor(
    public page: number = 1,
    public size: number = DefaultPaginationSize,
    public orderBy: string = null,
    public orderDirection: string = null,
    public searchText: string = null
  ) {}
}

export class CreateSupportIssue {
  public static readonly type = '[Support Issues] Create Support Issue';

  constructor(public createSupportIssueRequest: CreateSupportIssueRequest) {}
}

export class UpdateSupportIssue {
  public static readonly type = '[Support Issues] Update Support Issue';

  constructor(public updateSupportIssueRequest: UpdateSupportIssueRequest) {}
}
