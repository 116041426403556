import { MasterSchemaDto, MasterSchemeListDtoItemsResult } from '@api/model/models';
export interface ISchemaStateModel {
  masterSchemaItemsResult: MasterSchemeListDtoItemsResult;
  masterSchema: MasterSchemaDto;
}

export const schemaStateModelDefault = {
  name: 'Schema',
  defaults: {
    masterSchemaItemsResult: { items: [] } as MasterSchemeListDtoItemsResult,
    masterSchema: null as MasterSchemaDto,
  },
};
