/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DataType = 'Text' | 'Number' | 'Bool' | 'File' | 'CombinedField' | 'Date' | 'DateTime';

export const DataType = {
    Text: 'Text' as DataType,
    Number: 'Number' as DataType,
    Bool: 'Bool' as DataType,
    File: 'File' as DataType,
    CombinedField: 'CombinedField' as DataType,
    Date: 'Date' as DataType,
    DateTime: 'DateTime' as DataType
};

