/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CustomDateType = 'StegoStandard' | 'CustomDateFormat' | 'CustomDateCodes';

export const CustomDateType = {
    StegoStandard: 'StegoStandard' as CustomDateType,
    CustomDateFormat: 'CustomDateFormat' as CustomDateType,
    CustomDateCodes: 'CustomDateCodes' as CustomDateType
};

