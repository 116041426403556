import { JobType, SchemaAttributeDto, VariantSelectionDto } from '@api';
import { DefaultPaginationSize } from 'src/app/shared/paginationConstants';

export class CreatePrintJob {
  public static readonly type = '[Print] Create Print Job';

  constructor(
    public printerId: string,
    public labelId: string,
    public jobType: JobType,
    public identifier: string,
    public quantity: number,
    public labelPrintQuantity: number,
    public productionDate: string,
    public optionalPrintParameters: { [key: string]: string } = null
  ) {}
}

export class GetVariantGroups {
  public static readonly type = '[Print] Get variant groups';

  constructor(
    public page: number = 1,
    public size: number = DefaultPaginationSize,
    public orderBy: string = null,
    public orderDirection: string = null
  ) {}
}

export class GetPrintableArticles {
  public static readonly type = '[Print] Get printable Articles';

  constructor(
    public page: number = 1,
    public size: number = DefaultPaginationSize,
    public orderBy: string = null,
    public orderDirection: string = null,
    public searchText: string = null
  ) {}
}

export class GetArticlesOfVariantGroup {
  public static readonly type = '[Print] Get Articles of Variant Groups';

  constructor(public variantGroupId: string) {}
}

export class SelectArticleVariant {
  public static readonly type = '[Print] Select Article Variant';

  constructor(public selectedVariant: VariantSelectionDto) {}
}

export class GetVariantSelectionByBarcode {
  public static readonly type = '[Print] Select Article Variant by Barcode';

  constructor(
    public articleId: string,
    public variantGroupId: string
  ) {}
}

export class ResetArticleVariantSelection {
  public static readonly type = '[Print] Reset Article Variant Selection';

  constructor() {}
}

export class SetPrintTimeSchemaParameters {
  public static readonly type = '[Print] Set Printtime Schema Parameters';

  constructor(public printTimeSchemaParameters: SchemaAttributeDto[]) {}
}

export class GetPrinterSuggestions {
  public static readonly type = '[Print] Get Printer Suggestions';

  constructor(public labelTemplateIds: string[]) {}
}
