import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth/auth-guard.service';
import { PermissionGuard } from '../auth/guards/permission/permission-guard.guard';
import { RedirectorComponent } from '../auth/redirector/redirector.component';
import { permissions } from '../shared/constants/permission.constants';
import { IRouterData } from '../shared/interfaces/router-data.interface';
import { ErrorComponent } from './error-component/error.component';

const routes: Routes = [
  {
    path: 'articles',
    loadChildren: () => import('./master-data/articles/articles.module').then((m) => m.ArticlesModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.MasterData.Read],
    },
  },
  {
    path: 'labeltemplates',
    loadChildren: () => import('./master-data/label-templates/label-templates.module').then((m) => m.LabelTemplateModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.MasterData.Read],
    },
  },
  {
    path: 'schemes',
    loadChildren: () => import('./master-data/schemas/schemas.module').then((m) => m.SchemasModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.MasterData.Read],
    },
  },
  {
    path: 'attributegroups',
    loadChildren: () => import('./master-data/schemas/attribute-groups/attribute-groups.module').then((m) => m.AttributeGroupsModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.MasterData.Read],
    },
  },
  {
    path: 'variantgroups',
    loadChildren: () => import('./master-data/variant-groups/variant-groups.module').then((m) => m.VariantGroupsModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.MasterData.Read],
    },
  },
  {
    path: 'approbations',
    loadChildren: () => import('./master-data/approbations/approbation.module').then((m) => m.ApprobationModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.MasterData.Read],
    },
  },
  {
    path: 'printing',
    loadChildren: () => import('./printing/printing.module').then((m) => m.PrintingModule),
    data: { documentationLink: 'documentation/#/{{language}}/manuals/Print-Dialog', activateSupportIssue: true } as IRouterData,
    canActivate: [AuthGuardService],
  },
  {
    path: 'printers',
    loadChildren: () => import('./printer-management/printer-management.module').then((m) => m.PrinterManagementModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.PrinterManagement.Read],
    },
  },
  {
    path: 'supportIssues',
    loadChildren: () => import('./support-issues/support-issues.module').then((m) => m.SupportIssuesModule),
    canActivate: [AuthGuardService, PermissionGuard],
    data: {
      permissions: [permissions.SupportTickets.Read],
    },
  },
  {
    path: 'redirector',
    component: RedirectorComponent,
  },
  {
    path: '',
    redirectTo: '/redirector',
    pathMatch: 'full',
  },
  {
    path: 'error/:id',
    component: ErrorComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'logged-out',
    redirectTo: '/redirector',
  },
  {
    path: '**',
    redirectTo: '/error/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
