import { CreateMasterSchemaRequest, MasterSchemaDto } from '@api/model/models';
import { UpdateMasterSchemaRequest } from '@api/model/updateMasterSchemaRequest';
import { DefaultPaginationSize } from 'src/app/shared/paginationConstants';

export class CreateSchema {
  public static readonly type = '[Schema] Create Schema';

  constructor(public createMasterSchemaRequest: CreateMasterSchemaRequest) {}
}

export class UpdateSchema {
  public static readonly type = '[Schema] Update Schema';

  constructor(
    public masterSchemaId: string,
    public updateMasterSchemaRequest: UpdateMasterSchemaRequest
  ) {}
}

export class GetSchemes {
  public static readonly type = '[Schema] Get Schemes';

  constructor(
    public page: number = 1,
    public size: number = DefaultPaginationSize,
    public orderBy: string = null,
    public orderDirection: string = null,
    public searchText: string = null
  ) {}
}

export class GetSchema {
  public static readonly type = '[Schema] Get Schema';

  constructor(public masterSchemaId: string) {}
}

export class DeleteSchema {
  public static readonly type = '[Schema] Delete Schema';

  constructor(public masterSchema: MasterSchemaDto) {}
}

export class PatchSchema {
  public static readonly type = '[Schema] Patch Schema';

  constructor(public schema: MasterSchemaDto) {}
}
