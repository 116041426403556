/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type JobType = 'RawTemplate' | 'Variant';

export const JobType = {
    RawTemplate: 'RawTemplate' as JobType,
    Variant: 'Variant' as JobType
};

