<div class="container">
  <div class="row align-items-center h-100">
    <div class="col-6 mx-auto">
      <div class="col-md-12 text-center">
        <div>
          @switch (errorType) {
            @case (Forbidden) {
              <div>
                <i class="fas fa-ban fa-7x mb-3"></i>
                <div class="lead">{{ 'error.forbidden' | translation | async }}</div>
              </div>
            }
            @default {
              <div>
                <i class="fas fa-exclamation-circle fa-7x mb-3"></i>
                <div class="lead">{{ 'error.notFound' | translation | async }}</div>
              </div>
            }
          }
        </div>

        <button type="button" class="btn btn-primary mt-4" routerLink="/">{{ 'error.backToHome' | translation | async }}</button>
      </div>
    </div>
  </div>
</div>
