import { PrinterDtoItemsResult } from '@api';

export interface IPrinterManagementStateModel {
  printers: PrinterDtoItemsResult;
}

export const printerManagementStateModelDefault = {
  name: 'PrinterManagement',
  defaults: {
    printers: { items: [] } as PrinterDtoItemsResult,
  },
};
