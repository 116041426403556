import { Injectable } from '@angular/core';
import { LoadOptions, TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, filter, startWith, switchMap } from 'rxjs';
import * as masterDictionary from 'src/assets/i18n/de.json';
import { NestedPropertyOf } from '../types/nested-key-of.type';

@Injectable()
export class TranslationService {
  public languageChanges$ = this.translocoService.langChanges$.pipe(startWith(this.translocoService.getActiveLang()));
  public afterLanguageChanged$ = this.languageChanges$.pipe(
    distinctUntilChanged(),
    filter((language) => {
      return language != null;
    }),
    switchMap((language) => {
      return this.translocoService.load(language);
    })
  );

  constructor(private translocoService: TranslocoService) {}

  public getActiveLanguage() {
    return this.translocoService.getActiveLang();
  }

  public setActiveLanguage(language: string) {
    return this.translocoService.setActiveLang(language);
  }

  public load(path: string, options?: LoadOptions) {
    return this.translocoService.load(path, options);
  }

  public getAvailableLanguages() {
    const availableLanguages = this.translocoService.getAvailableLangs();
    return availableLanguages;
  }

  public translate(dictionaryKey: NestedPropertyOf<typeof masterDictionary>, params?: { [key: string]: string | number }) {
    return this.translocoService.translate(dictionaryKey, params);
  }
}
