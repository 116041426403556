import { Injectable } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import * as masterDictionary from 'src/assets/i18n/de.json';
import { TranslationService } from '../../../transloco-root/services/translation.service';
import { NestedPropertyOf } from '../../../transloco-root/types/nested-key-of.type';
import { ILabelManagerProblemDetails } from '../../interfaces/label-manager-problem-details.interface';
import { CustomToastComponent } from './custom-toastr/custom-toast.component';
import { IToastButton } from './custom-toastr/toast-button';
import { ToastrType } from './custom-toastr/toastr-type';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(
    private toastrService: ToastrService,
    private translationService: TranslationService
  ) {
    this.toastrService.toastrConfig.enableHtml = true;
    this.toastrService.toastrConfig.timeOut = 10_000;
    this.toastrService.toastrConfig.extendedTimeOut = 10_000;
    this.toastrService.toastrConfig.progressBar = true;
    this.toastrService.toastrConfig.closeButton = true;
    this.toastrService.toastrConfig.tapToDismiss = false;
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.toastrConfig.positionClass = 'toast-bottom-right';
  }

  public set notificationContainer(toastContainer: ToastContainerDirective) {
    this.toastrService.overlayContainer = toastContainer;
  }

  public plainSuccess(message: string, title?: string) {
    this.toastrService.success(message, title);
  }

  public success(message: NestedPropertyOf<typeof masterDictionary>, title?: NestedPropertyOf<typeof masterDictionary>) {
    const translatedMessage = this.translationService.translate(message);
    const translatedTitle = this.translationService.translate(title);
    this.toastrService.success(translatedMessage, translatedTitle);
  }

  public info(message: NestedPropertyOf<typeof masterDictionary>, title?: NestedPropertyOf<typeof masterDictionary>) {
    const translatedMessage = this.translationService.translate(message);
    const translatedTitle = this.translationService.translate(title);
    this.toastrService.info(translatedMessage, translatedTitle);
  }

  public warning(message: NestedPropertyOf<typeof masterDictionary>, title?: NestedPropertyOf<typeof masterDictionary>, warnings?: string[]) {
    const translatedMessage = this.translationService.translate(message);
    const translatedTitle = this.translationService.translate(title);
    let toastMessage = `<div class="notification-message">${translatedMessage}</div>`;

    if (warnings?.length) {
      toastMessage += '<ul class="validation-warning-messages">';

      for (const warning of warnings) {
        toastMessage += `<li class="validation-warning-message">${warning}</li>`;
      }

      toastMessage += '</ul>';
    }

    this.toastrService.warning(toastMessage, translatedTitle);
  }

  public error(message: NestedPropertyOf<typeof masterDictionary>, problemDetails?: ILabelManagerProblemDetails, params?: { [key: string]: string | number }) {
    const translatedMessage = this.translationService.translate(message, params);
    let toastMessage = `<div class="notification-message">${translatedMessage}</div>`;

    toastMessage = this.getValidationDetails(problemDetails, toastMessage);

    const correlationId = problemDetails?.extensions?.correlationId;
    if (correlationId != null) {
      toastMessage += `<div class="correlation-id">Correlation ID: ${correlationId}</div>`;
    }

    this.toastrService.error(toastMessage, problemDetails?.title);
  }

  private getValidationDetails(problemDetails: ILabelManagerProblemDetails, toastMessage: string) {
    if ((problemDetails?.errorCode ?? '').trim() != '') {
      const errorCode = `backend.${problemDetails.errorCode}` as NestedPropertyOf<typeof masterDictionary>;
      const parameterAsArray = problemDetails.parameters.map((parameter) => ({ [parameter.key]: parameter.value }));
      const parameters = Object.assign({}, ...parameterAsArray);

      const translatedMessage = this.translationService.translate(errorCode, parameters);

      toastMessage += `<div class="notification-error">${translatedMessage}</div>`;
    }

    return toastMessage;
  }

  public showCustomToastr(message: string, title?: string, buttons?: IToastButton[], toastrType = ToastrType.warning) {
    const individualConfig = {
      ...this.toastrService.toastrConfig,
      disableTimeOut: true,
      toastComponent: CustomToastComponent,
    };

    let activeToast = null;

    switch (toastrType) {
      case ToastrType.error:
        activeToast = this.toastrService.error(message, title, individualConfig);
        break;
      case ToastrType.info:
        activeToast = this.toastrService.info(message, title, individualConfig);
        break;
      case ToastrType.success:
        activeToast = this.toastrService.success(message, title, individualConfig);
        break;
      case ToastrType.warning:
        activeToast = this.toastrService.warning(message, title, individualConfig);
        break;
    }

    activeToast.toastRef.componentInstance.buttons = buttons;
    return activeToast;
  }
}
