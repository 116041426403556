import { PrinterSuggestion, SchemaAttributeDto, VariantGroupDtoItemsResult, VariantSelectionDto } from '@api/model/models';

export interface IPrintJobsStateModel {
  articlesOfVariantGroups: Array<VariantSelectionDto>;
  variantGroups: VariantGroupDtoItemsResult;
  selectedVariant: VariantSelectionDto;
  printTimeSchemaParameters: Array<SchemaAttributeDto>;
  printerSuggestions: Array<PrinterSuggestion>;
}

export const printJobsStateDefaults = {
  name: 'PrintJobs',
  defaults: {
    articlesOfVariantGroups: [] as Array<VariantSelectionDto>,
    variantGroups: [] as VariantGroupDtoItemsResult,
    selectedVariant: null as VariantSelectionDto,
    printTimeSchemaParameters: [] as Array<SchemaAttributeDto>,
    printerSuggestions: [] as Array<PrinterSuggestion>,
  },
};
