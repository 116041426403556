@if (navSidebar?.length) {
  <div class="nav">
    @for (navSidebarItem of navSidebar; track navSidebarItem) {
      <ng-container *appHasPermission="navSidebarItem.permissions">
        <div class="sb-sidenav-menu-heading text-white">{{ navSidebarItem?.displayName | translation | async }}</div>
        @for (menuItem of navSidebarItem?.menuItems; track menuItem) {
          <a
            [id]="menuItem.name"
            class="nav-link collapsed"
            [routerLink]="menuItem.routerLink"
            routerLinkActive="active"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="menuItem.subMenuItems?.length ? '#' + menuItem.name : '#pagesCollapseAuth'"
            aria-expanded="false"
            [attr.aria-controls]="menuItem.subMenuItems?.length ? menuItem.name : 'pagesCollapseAuth'"
            [class.sb-sidenav-menu-parent]="showMenu === menuItem.name"
            >
            <div class="sb-nav-link-icon"><i [class]="menuItem.icon"></i></div>
            {{ menuItem.displayName | translation | async }}
            @if (menuItem.subMenuItems?.length) {
              <div (click)="$event.preventDefault(); $event.stopPropagation(); addExpandClass(menuItem.name)" class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            }
          </a>
          <div [class.expand]="showMenu === menuItem.name" class="collapse" [id]="menuItem.name" data-bs-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
              @for (subMenuItem of menuItem.subMenuItems; track subMenuItem) {
                <a
                  [id]="subMenuItem.name"
                  class="nav-link collapsed"
                  [routerLink]="subMenuItem.routerLink"
                  routerLinkActive="active"
                  data-bs-toggle="collapse"
                  data-bs-target="#pagesCollapseAuth"
                  aria-expanded="false"
                  aria-controls="pagesCollapseAuth"
                  ><div class="sb-nav-link-icon"><i [class]="subMenuItem.icon"></i></div>
                  {{ subMenuItem.displayName | translation | async }}
                </a>
              }
            </nav>
          </div>
        }
      </ng-container>
    }
  </div>
}
