import { LabelTemplateConfiguration } from '../models/printConfigurationItem';

export interface ILabelPreviewStateModel {
  printTimeParameters: { key: string; value: string }[];
  labelTemplateConfiguration: LabelTemplateConfiguration[];
}

export const labelPreviewStateModelDefaults = {
  name: 'LabelPreview',
  defaults: {
    printTimeParameters: [] as Array<{ key: string; value: string }>,
    labelTemplateConfiguration: [] as Array<LabelTemplateConfiguration>,
  },
};
