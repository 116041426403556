import { Component } from '@angular/core';
import { navSidebarRepository } from './nav-sidebar-repository';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
})
export class NavSidebarComponent {
  public showMenu: string;
  public navSidebar = navSidebarRepository;

  public addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = null;
    } else {
      this.showMenu = element;
    }
  }
}
