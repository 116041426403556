import { Injectable } from '@angular/core';
import { LabelTemplateService, PrinterService, Status } from '@api';
import { VariantGroupService } from '@api/api/variantGroup.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CreatePrintJob, GetArticlesOfVariantGroup, GetPrinterSuggestions, GetVariantGroups, GetVariantSelectionByBarcode, ResetArticleVariantSelection, SelectArticleVariant, SetPrintTimeSchemaParameters } from './print.actions';
import { IPrintJobsStateModel, printJobsStateDefaults } from './print.state.model';

@State<IPrintJobsStateModel>(printJobsStateDefaults)
@Injectable()
export class PrintState {
  @Selector([PrintState])
  public static getPrinterSuggestion(state: IPrintJobsStateModel) {
    return (labelTemplateId: string) => {
      return state.printerSuggestions.find((labelTemplate) => labelTemplate.labelTemplateId === labelTemplateId);
    };
  }

  @Selector([PrintState])
  public static getArticlesOfVariantGroups(state: IPrintJobsStateModel) {
    return state.articlesOfVariantGroups;
  }

  @Selector([PrintState])
  public static getSelectedVariant(state: IPrintJobsStateModel) {
    return state.selectedVariant;
  }

  @Selector([PrintState])
  public static getSelectedVariantLink(state: IPrintJobsStateModel): string {
    const variantsLinkBase = `variantgroups/${state.selectedVariant.variantGroupId}/variants`;
    const variantId = state.selectedVariant.variantId;
    const selectedVariantLink = `/${variantsLinkBase}/${variantId}/details`;
    return selectedVariantLink;
  }

  @Selector([PrintState])
  public static isSelectedVariantPrintable(state: IPrintJobsStateModel): boolean {
    return state.selectedVariant?.isActive === true && state.selectedVariant?.status === Status.Configured;
  }

  @Selector([PrintState])
  public static getVariantGroups(state: IPrintJobsStateModel) {
    return state.variantGroups;
  }

  @Selector([PrintState])
  public static getPrintTimeParameters(state: IPrintJobsStateModel) {
    return state.printTimeSchemaParameters;
  }

  constructor(
    private printerService: PrinterService,
    private labelTemplateService: LabelTemplateService,
    private variantGroupService: VariantGroupService
  ) {}

  @Action(CreatePrintJob)
  public createPrintJob(_: StateContext<IPrintJobsStateModel>, action: CreatePrintJob) {
    return this.printerService.createPrintJob(action.printerId, action.optionalPrintParameters, action.labelId, action.jobType, action.identifier, action.quantity, action.labelPrintQuantity, action.productionDate);
  }

  @Action(GetArticlesOfVariantGroup)
  public getArticlesOfVariantGroup({ patchState }: StateContext<IPrintJobsStateModel>, action: GetArticlesOfVariantGroup) {
    return this.variantGroupService.getPrintableVariantsForVariantGroup(action.variantGroupId).pipe(
      tap((articlesOfVariantGroups) => {
        patchState({
          articlesOfVariantGroups: articlesOfVariantGroups,
        });
      })
    );
  }

  @Action(GetPrinterSuggestions)
  public getPrinterSuggestions({ patchState }: StateContext<IPrintJobsStateModel>, action: GetPrinterSuggestions) {
    return this.printerService.getPrinterSuggestions(action.labelTemplateIds).pipe(
      tap((printerSuggestions) => {
        patchState({
          printerSuggestions: printerSuggestions,
        });
      })
    );
  }

  @Action(GetVariantGroups)
  public getVariantGroups({ patchState }: StateContext<IPrintJobsStateModel>, action: GetVariantGroups) {
    return this.variantGroupService.getRawVariantGroups(action.page, action.size, action.orderBy, action.orderDirection).pipe(
      tap((variantGroups) => {
        patchState({
          variantGroups,
        });
      })
    );
  }

  @Action(GetVariantSelectionByBarcode)
  public getVariantSelectionByBarcode({ patchState }: StateContext<IPrintJobsStateModel>, action: GetVariantSelectionByBarcode) {
    return this.labelTemplateService.getLabelTemplatesByVariantGroupAndArticleId(action.articleId, action.variantGroupId).pipe(
      tap((response) => {
        patchState({
          selectedVariant: response.variantSelectionDto,
          printTimeSchemaParameters: response.printTimeVariables,
        });
      })
    );
  }

  @Action(ResetArticleVariantSelection)
  public resetSelectedVariant({ patchState }: StateContext<IPrintJobsStateModel>, _: ResetArticleVariantSelection) {
    patchState({ selectedVariant: null, printTimeSchemaParameters: [], articlesOfVariantGroups: [] });
  }

  @Action(SelectArticleVariant)
  public selectVariant({ patchState }: StateContext<IPrintJobsStateModel>, action: SelectArticleVariant) {
    patchState({ selectedVariant: action.selectedVariant });
  }

  @Action(SetPrintTimeSchemaParameters)
  public setPrintTimeSchemaParameters({ patchState }: StateContext<IPrintJobsStateModel>, action: SetPrintTimeSchemaParameters) {
    patchState({ printTimeSchemaParameters: action.printTimeSchemaParameters });
  }
}
