<ng-container>
  @if (isUserLoggedIn$ | async) {
    <ng-container class="sb-nav-fixed">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark navbar-z-index fixed-top nav-bar-general-style">
        <div class="container-fluid">
          <!-- Sidebar Toggler -->
          <button class="link-light shadow-none customized-navbar-toggler" id="sidebarToggle" (click)="toggleSidebar()">
            <i class="fas fa-bars"></i>
          </button>
          <!-- Navbar Brand-->
          <a class="navbar-brand me-auto" routerLink="/">{{ tenantName$ | async }}</a>
          <!-- Searchbar Toggler-->
          <button
            class="navbar-toggler shadow-none pl-2 pr-2 pt-1 pb-1 customized-navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            >
            <span class="customized-right-toggler">...</span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <ul class="navbar-nav mb-2 mb-lg-0 align-items-lg-center">
              <!-- Navbar Search-->
              <li ngbDropdown class="nav-item dropdown" #searchDropdown="ngbDropdown">
                <form *appHasPermission="permissions.MasterData.Read" class="d-flex search-bar-wrapper">
                  <div class="input-group">
                    <input
                      #searchInput
                      class="form-control search-textbox shadow-none input-sm"
                      type="text"
                      (keyup)="searchTerm$.next($event.target.value)"
                      (change)="onSearchInputChanged($event.target.value)"
                      [value]="searchInputText"
                      [placeholder]="'portal.searchPlaceholder' | translation | async"
                      />
                  </div>
                  <button class="btn btn-primary btn-sm search-button shadow-none" id="dropdownManual" ngbDropdownToggle (click)="search()"><i class="fas fa-search"></i></button>
                </form>
                @if (hasSearchResults) {
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownManual">
                    @for (searchResult of searchResults; track searchResult) {
                      @if (searchResult.count > 0 && searchResult.hasPermission) {
                        <app-standard-dropdown-card
                          ngbDropdownItem
                          [cardTitle]="searchResult.title"
                          [cardContent]="searchResult.message"
                          [links]="[searchResult.link]"
                        ></app-standard-dropdown-card>
                      }
                    }
                    @if ((searchResult$ | async) === null || (searchResult$ | async) === undefined) {
                      Laden...
                    }
                  </div>
                }
                @if (!hasSearchResults) {
                  <div ngbDropdownMenu class="dropdown-menu p-1" aria-labelledby="dropdownManual">
                    {{ 'portal.noResults' | translation : { searchText: getSearchText() } | async }}
                  </div>
                }
              </li>
              <!-- help link -->
              @if (routerData?.documentationLink) {
                <li class="nav-item">
                  <a id="helpLink" class="nav-link" [href]="routerData.documentationLink | interpolateString : { language: language$ | async }" target="_blank"
                    ><i class="fas fa-question-circle fa-lg"></i> {{ 'portal.help' | translation | async }}</a
                    >
                  </li>
                }
                <!-- report support issue link -->
                @if (routerData?.activateSupportIssue === true) {
                  <li class="nav-item">
                    <a id="report-support-issue" class="nav-link" (click)="openReportSupportIssue()"><i class="fas fa-envelope-open fa-lg"></i> {{ 'supportIssue.reportSupportIssue' | translation | async }}</a>
                  </li>
                }
                <!-- User-->
                <li class="nav-item">
                  <a class="nav-link logout-link" [ngbTooltip]="'portal.logout' | translation | async" (click)="logout()"><i class="fas fa-sign-out-alt fa-lg mr-1"></i> {{ username$ | async }}</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
              <div class="sb-sidenav-menu">
                <app-nav-sidebar></app-nav-sidebar>
              </div>
              <div class="language-selection-option" (click)="setNextLanguage()">
                <span class="fi fi-{{ language$ | async }} fis flag-icon"></span>
                <div>{{ 'defaults.localLanguage' | translation | async }}</div>
              </div>
              <div class="sb-sidenav-footer">
                <div>{{ version.environment }} Version {{ version.number }}</div>
              </div>
            </nav>
          </div>
          <div id="layoutSidenav_content">
            <main>
              <router-outlet (activate)="onRouterOutletActivated($event)"></router-outlet>
            </main>
            <footer class="bg-light mt-auto sidenav-content-footer">
              <div class="container-fluid px-4">
                <div class="d-flex align-items-center justify-content-between small">
                  <div class="text-muted">Copyright &copy; STEGO Elektrotechnik GmbH {{ currentYear }}</div>
                  <div>
                    <a class="pe-1" href="#">{{ 'portal.privacyPolicy' | translation | async }}</a>
                    &middot;
                    <a class="ps-1" href="#">{{ 'portal.legalNotice' | translation | async }}</a>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </ng-container>
    }
  </ng-container>
