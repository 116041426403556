/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LabelType = 'TLabel' | 'ALabel' | 'KLabel' | 'ZLabel';

export const LabelType = {
    TLabel: 'TLabel' as LabelType,
    ALabel: 'ALabel' as LabelType,
    KLabel: 'KLabel' as LabelType,
    ZLabel: 'ZLabel' as LabelType
};

