import { SupportIssueDtoItemsResult } from '@api';

export interface ISupportIssuesStateModel {
  supportIssues: SupportIssueDtoItemsResult;
}

export const supportIssuesStateModelDefault = {
  name: 'SupportIssues',
  defaults: {
    supportIssues: { items: [] } as SupportIssueDtoItemsResult,
  },
};
