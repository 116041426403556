<div class="standard-textbox-container input-group-prepend">
  @if (addOnText) {
    <span class="input-group-text standard-textbox-add-on" id="add-on-text">{{ addOnText }}</span>
  }

  <div class="w-100">
    @if (replaceTextbox) {
      <ng-content select="[textbox]"></ng-content>
    }

    <input
      #targetInputElement
      [type]="shouldBeNumber ? 'number' : 'input'"
      class="standard-textbox"
      [placeholder]="placeholder"
      [formControl]="valueControl"
      (keydown)="onKeyDown($event)"
      (paste)="onPaste($event)"
      (input)="onChange()"
      (change)="onChange()"
      (blur)="onTouchedFn()"
      [id]="id"
      [name]="id"
      [class.d-none]="replaceTextbox"
      [ngClass]="{
        'd-none': replaceTextbox,
        'invisible-placeholder': !placeholder,
        'textbox-content-replaced': replaceTextbox,
        'is-required': isRequired,
      }"
      aria-describedby="add-on-text"
    />

    @if (label) {
      <label [for]="id" [class.textbox-caption-on-top]="replaceTextbox" class="textbox-caption" [class.required]="isRequired">{{ label }} </label>
    }

    @if (hint?.length) {
      <small class="form-text text-muted"><i class="far fa-hand-point-right"></i>{{ hint }}</small>
    }

    <ng-container>
      @if (control?.errors?.required && (control?.dirty || control?.touched)) {
        <div id="property-required-hint" class="form-text text-danger">
          <i class="fas fa-exclamation-circle"></i>
          @if (validationError) {
            <span> {{ validationError }}</span>
          } @else {
            {{ 'defaults.validation.isRequired' | translation: { label: label } | async }}
          }
        </div>
      }
      @if (control?.errors?.maxlength && (control?.dirty || control?.touched)) {
        <div id="property-exceeds-max-length-hint" class="form-text text-danger">
          <i class="fas fa-exclamation-circle"></i> {{ 'defaults.validation.maxLength' | translation: { label: label, length: control?.errors?.maxlength.requiredLength } | async }}
        </div>
      }
      @if (control?.errors?.minlength && (control?.dirty || control?.touched)) {
        <div class="form-text text-danger"><i class="fas fa-exclamation-circle"></i> {{ 'defaults.validation.minLength' | translation: { label: label, length: control?.errors?.minlength.requiredLength | async } }}</div>
      }
    </ng-container>
  </div>
</div>
