/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LanguageIdentifier = 'De' | 'En' | 'Es' | 'Fr' | 'It' | 'JP' | 'Pl' | 'Pt';

export const LanguageIdentifier = {
    De: 'De' as LanguageIdentifier,
    En: 'En' as LanguageIdentifier,
    Es: 'Es' as LanguageIdentifier,
    Fr: 'Fr' as LanguageIdentifier,
    It: 'It' as LanguageIdentifier,
    Jp: 'JP' as LanguageIdentifier,
    Pl: 'Pl' as LanguageIdentifier,
    Pt: 'Pt' as LanguageIdentifier
};

