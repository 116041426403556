import { permissions } from 'src/app/shared/constants/permission.constants';
import { INavSidebar } from './nav-sidebar.interface';

export const navSidebarRepository: INavSidebar[] = [
  {
    displayName: 'defaults.masterData',
    permissions: permissions.MasterData.Read,
    menuItems: [
      {
        name: 'articles',
        displayName: 'article.plural',
        routerLink: './articles',
        icon: 'fas fa-tags',
      },
      {
        name: 'variantGroups',
        displayName: 'variantGroup.plural',
        routerLink: './variantgroups',
        icon: 'fas fa-list-ul',
      },
      {
        name: 'labelTemplates',
        displayName: 'labelTemplate.plural',
        routerLink: './labeltemplates',
        icon: 'fas fa-table',
      },
      {
        name: 'schemes',
        displayName: 'masterSchema.plural',
        routerLink: './schemes',
        icon: 'fab fa-buffer',
        subMenuItems: [
          {
            name: 'AttributeGroups',
            displayName: 'attributeGroup.plural',
            routerLink: './attributegroups',
            icon: 'fas fa-bezier-curve',
          },
        ],
      },
      {
        name: 'approbations',
        displayName: 'approbation.plural',
        routerLink: './approbations',
        icon: 'fas fa-file-signature',
      },
    ],
  },
  {
    displayName: 'defaults.print',
    permissions: permissions.Print.Execute,
    menuItems: [
      {
        name: 'printing',
        displayName: 'defaults.print',
        routerLink: './printing',
        icon: 'fas fa-print',
      },
      {
        name: 'printers',
        displayName: 'defaults.printerManagement',
        routerLink: './printers',
        icon: 'fas fa-server',
      },
    ],
  },
  {
    displayName: 'defaults.administration',
    permissions: permissions.SupportTickets.Read,
    menuItems: [
      {
        name: 'supportIssues',
        displayName: 'supportTickets.plural',
        routerLink: './supportIssues',
        icon: 'fas fa-envelope-open',
      },
    ],
  },
];
