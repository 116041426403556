import { Injectable } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../transloco-root/services/translation.service';
import { LoadingService } from './services/loading.service';
import { NotificationsService } from './services/notifications-service/notifications.service';

@Injectable({ providedIn: 'root' })
export class ComponentBaseService {
  constructor(
    public store: Store,
    public notificationsService: NotificationsService,
    public actions$: Actions,
    public spinnerService: NgxSpinnerService,
    public loadingService: LoadingService,
    public translationService: TranslationService
  ) {}
}
