import { Injectable } from '@angular/core';
import { MasterSchemaService } from '@api/api/masterSchema.service';
import { MasterSchemaDto, MasterSchemeListDtoItemsResult } from '@api/model/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreateSchema, DeleteSchema, GetSchema, GetSchemes, PatchSchema, UpdateSchema } from './schema.actions';
import { ISchemaStateModel, schemaStateModelDefault } from './schema.state.model';

@State<ISchemaStateModel>(schemaStateModelDefault)
@Injectable()
export class SchemaState {
  @Selector([SchemaState])
  public static getMasterSchemes(state: ISchemaStateModel) {
    return state.masterSchemaItemsResult;
  }

  @Selector([SchemaState])
  public static getMasterSchema(state: ISchemaStateModel) {
    return state.masterSchema;
  }
  constructor(private masterSchemaService: MasterSchemaService) {}

  @Action(CreateSchema)
  public createSchema({ patchState, getState }: StateContext<ISchemaStateModel>, action: CreateSchema): Observable<MasterSchemaDto> {
    return this.masterSchemaService.createMasterSchema(action.createMasterSchemaRequest).pipe(
      tap((masterSchema) => {
        const state = getState();
        patchState({
          ...state,
          masterSchemaItemsResult: { items: [...state.masterSchemaItemsResult?.items, masterSchema] } as MasterSchemeListDtoItemsResult,
          masterSchema: masterSchema,
        });
      })
    );
  }

  @Action(DeleteSchema)
  public deleteSchema({ patchState, getState }: StateContext<ISchemaStateModel>, action: DeleteSchema) {
    return this.masterSchemaService.deleteMasterSchema(action.masterSchema.id, action.masterSchema.version).pipe(
      tap(() => {
        const state = getState();
        patchState({
          ...state,
          masterSchemaItemsResult: { items: state.masterSchemaItemsResult?.items?.filter((item) => item?.id != action.masterSchema?.id) },
        });
      })
    );
  }

  @Action(GetSchema)
  public getSchema({ patchState }: StateContext<ISchemaStateModel>, action: GetSchema) {
    return this.masterSchemaService.getMasterSchema(action.masterSchemaId).pipe(
      tap((masterScheme) => {
        for (let attributeGroup of masterScheme.attributeGroups) {
          const masterSchemaAttributeGroupMapping = masterScheme.attributeGroupMasterSchemes.find((ag) => ag.attributeGroupsId === attributeGroup.id);
          attributeGroup.name = masterSchemaAttributeGroupMapping.attributeGroupName;
        }

        patchState({
          masterSchema: masterScheme,
        });
      })
    );
  }

  @Action(GetSchemes)
  public getSchemes({ patchState }: StateContext<ISchemaStateModel>, action: GetSchemes) {
    return this.masterSchemaService.getMasterScheme(action.page, action.size, action.orderBy, action.orderDirection, action.searchText).pipe(
      tap((masterScheme) => {
        patchState({
          masterSchemaItemsResult: masterScheme,
        });
      })
    );
  }

  @Action(PatchSchema)
  public patchSchema({ patchState }: StateContext<ISchemaStateModel>, action: PatchSchema) {
    patchState({
      masterSchema: action.schema,
    });
  }

  @Action(UpdateSchema)
  public updateSchema({ patchState, getState }: StateContext<ISchemaStateModel>, action: UpdateSchema) {
    return this.masterSchemaService.updateMasterSchema(action.masterSchemaId, action.updateMasterSchemaRequest).pipe(
      tap((masterSchema) => {
        const state = getState();
        patchState({
          ...state,
          masterSchemaItemsResult: { items: [...state.masterSchemaItemsResult?.items, masterSchema] } as MasterSchemeListDtoItemsResult,
          masterSchema: masterSchema,
        });
      })
    );
  }
}
