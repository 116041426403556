export const permissions = {
  MasterData: {
    Read: 'MasterData.Read',
    Write: 'MasterData.Write',
  },
  Preview: {
    Execute: 'Preview.Execute',
    Jobs: {
      Read: 'Preview.Jobs.Read',
      Write: 'Preview.Jobs.Write',
    },
  },
  Print: {
    Execute: 'Print.Execute',
    Jobs: {
      Read: 'Print.Jobs.Read',
      Write: 'Print.Jobs.Write',
    },
    ProductionCountry: {
      Write: 'Print.ProductionCountry.Write',
    },
    VariantSelection: {
      Execute: 'Print.VariantSelection.Execute',
    },
  },
  PrinterManagement: {
    BandSize: {
      Update: 'PrinterManagement.BandSize.Update',
    },
    Read: 'PrinterManagement.Read',
    Write: 'PrinterManagement.Write',
  },
  SupportTickets: {
    Read: 'SupportTickets.Read',
    Update: 'SupportTickets.Update',
  },
};
