@if (rows?.length > 0 || (sourceObservable | async); as source) {
  <ngx-datatable
    #dataTable
    class="material striped expandable"
    (page)="changePage($event)"
    (select)="selectRow($event)"
    [externalPaging]="externalPaging"
    [externalSorting]="externalSorting"
    (sort)="onSort($event)"
    [sorts]="sorts"
    [count]="source?.pages?.totalItems ?? rows?.length"
    [offset]="internalPageNumber"
    [limit]="source?.pages?.size ?? DefaultPaginationSize"
    [rows]="source?.items ?? rows"
    [columns]="columns"
    [columnMode]="ColumnMode.force"
    [selectionType]="selectionType"
    [headerHeight]="45"
    [rowHeight]="rowHeight"
    [footerHeight]="50"
    [messages]="messages$ | async"
  >
    <!-- Row Detail Template -->
    <ngx-datatable-row-detail [rowHeight]="110">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div>
          <ng-container *ngTemplateOutlet="expandableRowContent; context: row"></ng-container>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <!-- Column Templates -->
    <ng-template #expandableColumnTemplate let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
      <button class="datatable-button" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Details anzeigen" (click)="toggleExpandRow(row, !expanded)"></button>
    </ng-template>
  </ngx-datatable>
}
