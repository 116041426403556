import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-support-issue',
  templateUrl: './create-support-issue.component.html',
  styleUrls: ['./create-support-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSupportIssueComponent implements OnInit {
  @Input() public diagnosticData: string;

  public form: UntypedFormGroup;

  constructor(private activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(128)]),
      description: new UntypedFormControl(null, [Validators.maxLength(2048)]),
      diagnosticData: new UntypedFormControl(this.diagnosticData, [Validators.maxLength(2048)]),
    });
  }

  public sendProblem() {
    if (this.form.invalid) {
      return;
    }

    this.activeModal.close(this.form.getRawValue());
  }

  public cancel() {
    this.activeModal.close('close');
  }
}
