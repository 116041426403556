import { Injectable } from '@angular/core';
import { UserTenantInformation } from '@api/model/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization/authorization.service';
import { TenantService } from './../services/api/api/tenant.service';
import { InitOAuth, Logout, Search, SetSearchText } from './application.actions';
import { IApplicationStateModel, applicationStateModelDefault } from './application.state.model';

@State<IApplicationStateModel>(applicationStateModelDefault)
@Injectable()
export class ApplicationState {
  @Selector([ApplicationState])
  public static getUsername(state: IApplicationStateModel) {
    return state.username;
  }

  @Selector([ApplicationState])
  public static isUserLoggedIn(state: IApplicationStateModel) {
    return state.isUserLoggedIn;
  }

  @Selector([ApplicationState])
  public static getSearchResult(state: IApplicationStateModel) {
    return state.searchResult;
  }

  @Selector([ApplicationState])
  public static getSearchText(state: IApplicationStateModel) {
    return state.searchText;
  }

  @Selector([ApplicationState])
  public static getTenantName(state: IApplicationStateModel) {
    return state.userTenantInformation?.tenant?.name ?? 'STEGO Lable Manager';
  }

  @Selector([ApplicationState])
  public static permissions(state: IApplicationStateModel) {
    const permissions = state.userTenantInformation?.permissions;
    if (!Array.isArray(permissions)) {
      return [];
    }

    return permissions.map((permission) => permission.name.toLowerCase()) as string[];
  }

  constructor(
    private authorizationService: AuthorizationService,
    private tenantService: TenantService
  ) {}

  @Action(InitOAuth)
  public async initOAuth({ patchState }: StateContext<IApplicationStateModel>, action: InitOAuth) {
    if (action.userTenantInformation == null) {
      patchState({
        isUserLoggedIn: false,
        username: null,
        userTenantInformation: {} as UserTenantInformation,
      });

      return;
    }

    const tenant = action.userTenantInformation.tenant;
    if ((tenant.name?.trim() ?? '') === '') {
      tenant.name = 'STEGO Label Manager';
    }

    patchState({
      isUserLoggedIn: true,
      username: this.authorizationService.getClaims(),
      userTenantInformation: action.userTenantInformation,
    });
  }

  @Action(Logout)
  public logout({ patchState }: StateContext<IApplicationStateModel>, _: Logout) {
    patchState({
      isUserLoggedIn: false,
    });
    this.authorizationService.logout();
  }

  @Action(Search)
  public search({ patchState }: StateContext<IApplicationStateModel>, action: Search) {
    return this.tenantService.search(action.searchText).pipe(
      tap((searchResult) => {
        patchState({
          searchResult: searchResult,
        });
      })
    );
  }

  @Action(SetSearchText)
  public setSearchText({ patchState }: StateContext<IApplicationStateModel>, action: SetSearchText) {
    patchState({
      searchText: action.searchText ?? '',
    });
  }
}
