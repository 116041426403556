<div class="modal-header">
  <h4 id="send-problem-header" class="modal-title">{{ 'supportIssue.reportSupportIssue' | translation | async }}</h4>
  <button type="button" class="btn btn-light" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body fw-normal" (click)="$event.preventDefault(); $event.stopPropagation()">
  <form [formGroup]="form">
    <app-standard-textbox id="title" [label]="'supportIssue.views.list.properties.title' | translation | async" [formControl]="form.get('title')"> </app-standard-textbox>

    <app-standard-textbox [replaceTextbox]="true" [label]="'properties.description' | translation | async" [formControl]="form.get('description')">
      <div textbox>
        <textarea formControlName="description" id="description" cols="30" rows="10" class="description-textbox standard-textbox"></textarea>
      </div>
    </app-standard-textbox>
  </form>
</div>
<div class="modal-footer">
  <button id="send-problem-button" type="button" class="btn btn-primary" (click)="sendProblem()" [disabled]="!form.valid || !form.dirty">{{ 'actions.send' | translation | async }}</button>
  <button type="button" class="btn btn-secondary ms-2" (click)="cancel()">{{ 'actions.abort' | translation | async }}</button>
</div>
