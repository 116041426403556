import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgWizardModule } from 'ng-wizard3';
import { RedirectorComponent } from '../auth/redirector/redirector.component';
import { ngWizardConfig } from '../shared/constants/ng-wizard-config.constant';
import { SharedModule } from '../shared/shared.module';
import { ErrorComponent } from './error-component/error.component';
import { LabelPreviewModule } from './label-preview/label-preview.module';
import { SchemaState } from './master-data/schemas/state/schema.state';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { PortalComponent } from './portal.component';
import { PortalRoutingModule } from './portal.routing.module';
import { PrinterManagementState } from './printer-management/state/printer-management.state';
import { SupportIssuesState } from './support-issues/state/support-issues.state';

@NgModule({
  declarations: [PortalComponent, RedirectorComponent, ErrorComponent, NavSidebarComponent],
  imports: [PortalRoutingModule, SharedModule.forRoot(), LabelPreviewModule, NgWizardModule.forRoot(ngWizardConfig), NgxsModule.forFeature([SchemaState, PrinterManagementState, SupportIssuesState])],
  exports: [PortalComponent, ErrorComponent],
})
export class PortalModule {}
