/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from './notifications-service/notifications.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationsService: NotificationsService,
    private router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accept = request.headers.get('Accept');
    if (accept === 'application/zip') {
      request = request.clone({
        responseType: 'blob',
      });
    }

    return next
      .handle(request)
      .pipe(
        catchError((event: HttpErrorResponse) => {
          if (event.error instanceof ErrorEvent) {
            this.notificationsService.warning('Client side error occurred.' as any);
          } else if (event.status === HttpStatusCode.Forbidden) {
            this.router.navigate([`/error/${HttpStatusCode.Forbidden}`]);
          } else if (event.status === HttpStatusCode.NotFound) {
            this.router.navigate([`/error/${HttpStatusCode.NotFound}`]);
          }

          return throwError(event);
        })
      )
      .pipe(
        map((event: HttpEvent<any>) => {
          return event;
        })
      );
  }
}
